<div [ngClass]="(attribute.type === 'bankingInfo')?'fxFlex_70':'fxFlex_40'" fxFlex_lt-sm="40" fxFlex_lt-md="25" class="p-2">
{{attribute.label}}:</div>
  <div [ngClass]="(attribute.type === 'bankingInfo')?'fxFlex_30':'fxFlex_60'" fxFlex_lt-sm="60" fxFlex_lt-md="75"
  class="p-2 font-weight-bold text-break"
  [ngClass]="(attribute.class || '') + ' ' + (attribute.dataClass? formDetails[attribute.dataValue] : '')">
  @if (attribute.type === 'string' && !attribute.translate) {
    <span
    >{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
  }
  @if (attribute.type === 'string' && attribute.translate) {
    <span
      >{{formDetails.getDeepData(attribute.name) | labelTranslate}}
    </span>
  }
  @if (attribute.type === 'date') {
    <span
      >{{formDetails.getDeepData(attribute.name)  | date: 'short' : uiTimezone}}
    </span>
  }
  @if (attribute.type === 'currency') {
    <span
      >{{formDetails.getDeepData(attribute.name)  | currency:'USD':'symbol'}}
    </span>
  }
  @if (attribute.type === 'bankingInfo') {
    <span>{{formDetails.getDeepData(attribute.name)}}
    </span>
  }
  @if (attribute.type === 'bankingInfoDoc') {
    <span class="download-btn">
      <mat-button (click)="downloadPdf('data:application/pdf;base64,'+ formDetails.getDeepData(attribute.name))">
        <mat-icon>cloud_download</mat-icon>
      </mat-button>
    </span>
  }
</div>