import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploaderConfig, UploaderMode } from '@londonhydro/ux-lib';
import * as _ from 'underscore';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {

  viewMode = UploaderMode.VIEWER;
  fileCategories: Array<any>;
  fileListWithCategory: any;
  fileViewerConfig = {};

  constructor(public dialogRef: MatDialogRef<FileViewerComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,
              private titleCasePipe: TitleCasePipe) {
    this.fileCategories = new Array();
    if (modalData) {
      const lthis = this;
      const fcats = _.allKeys(modalData);

      _.each(fcats, (category: string) => {
        if (modalData[category] != null && !_.isEmpty(modalData[category])) {
          this.fileCategories.push(category);
        }
      });

      this.fileListWithCategory = modalData;
      _.each(this.fileCategories, (category: string) => {
        lthis.fileViewerConfig[category] = lthis.buildFileUploadConfigAsViewer(this.titleCasePipe.transform(category));
      });
      console.log('this.fileCategories::', this.fileCategories, this.fileListWithCategory);
    }
  }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  private buildFileUploadConfigAsViewer(ulabel?: string): FileUploaderConfig {
    const uConfig = new FileUploaderConfig();
    if (ulabel) {
      uConfig.replaceTexts.selectFileBtn = ulabel;
    } else {
      uConfig.replaceTexts.selectFileBtn = '';
    }
    return uConfig;
  }
}
