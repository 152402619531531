import { Component, OnInit } from '@angular/core';
import { ChartData, UIUtil } from '@londonhydro/utility-model';
import { ChartDonutSeriesConfig, PieChartConfig} from '@londonhydro/energyusage-api'
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import * as _ from 'underscore';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  statChart: Chart;
  chartDataList: ChartData[];

  constructor() {
    const cData1 = new ChartData('Submitted', 10);
    const cData2 = new ChartData('In Queue', 15);
    const cData3 = new ChartData('Submitted to North Star', 4);
    const cData4 = new ChartData('Submitted to Billing', 6);
    const cData5 = new ChartData('Exception', 1);
    const cData6 = new ChartData('Completed', 28);

    this.chartDataList = new Array();
    this.chartDataList.push(cData1);
    this.chartDataList.push(cData2);
    this.chartDataList.push(cData3);
    this.chartDataList.push(cData4);
    this.chartDataList.push(cData5);
    this.chartDataList.push(cData6);

    this.loadStatChartOption();
  }

  ngOnInit(): void {
    const statSeriesConfig = new ChartDonutSeriesConfig();
    statSeriesConfig.data = this.chartDataList;
    statSeriesConfig.colors = ['#FFE900', '#EF3340', '#64A70B', '#934D11', '#0057B8', '#B36924', '#934D11', '#4F2C1D'];
    this.statChart.addSeries(statSeriesConfig as Highcharts.SeriesPieOptions, true, false);
  }

  loadStatChartOption(): void {
    const _this = this;
    const chartOptions = new PieChartConfig(UIUtil.UiTimeZone);

    chartOptions.title.text = '<b>Total <br/><span>' + (_.reduce(this.chartDataList, (memo, val) => {
      return memo + val.value;
    }, 0)) + '</span></b>';
    chartOptions.legend.enabled = false;

    chartOptions.lang.noData = 'No data to display for this period';

    chartOptions.title.verticalAlign = 'middle';
    chartOptions.title.style.textAlign = 'center';
    chartOptions.title.y = -75;
    chartOptions.plotOptions.pie.events = {
      click: (event: any): void => {
        _this.pieExpandHandler(event, _this.statChart);
      }
    };

    this.statChart = new Chart(chartOptions);
  }

  private pieExpandHandler(event: any, chartObj: any): void {
    const selectedPoint = event.point;
    const chartExpanded = !selectedPoint.selected;
    console.log('this.selectedPoint:::', selectedPoint);
    let chartTitle = '';
    if (chartExpanded) {
      chartTitle = '<b>' + selectedPoint.name + '<br/><span>' + selectedPoint.value + '</span></b>';
    } else {
      chartTitle = '<b>Total <br/><span>' + selectedPoint.total + '</span></b>';
    }
    chartObj.ref$.subscribe(chart => {
      chart.setTitle({ text: chartTitle });
    });
  }
}
