@if (isCustomerViewClicked) {
  <mat-card fxLayout="column">
    <mat-card-header>
      <a>
        <h2 class="hand-cursor uppcase" (click)="redirectToParent()">&#60;&nbsp;Customer Details</h2>
      </a>
    </mat-card-header>
    @if (CustomerViewData) {
      <mat-card-content>
        <div fxLayout="column">
          <div fxFlex="100">
            <h4>
              Customer Details
              <mat-divider></mat-divider>
            </h4>
          </div>
          <div fxFlex="100">
            <span><span>Customer Id:</span><span>{{CustomerViewData.customerId}}</span></span>
          </div>
          <div fxFlex="100">
            <span><span>Full Name:</span><span>{{CustomerViewData.firstName}} {{CustomerViewData.lastName}}</span></span>
          </div>
        </div>
        <div fxLayout="column">
          <div fxLayout="column">
            <h4>
              User Details
              <mat-divider></mat-divider>
            </h4>
          </div>
          <div fxLayout="row">
            <span>Login Email:</span>
            <span>{{CustomerViewData.user ! == null? CustomerViewData.customerId + '(Not Yet Registered)': CustomerViewData.user.username}}</span>
          </div>
        </div>
        <div fxLayout="column">
          <div fxFlex="100">
            <h3>
              Web Account Activity
              <mat-divider></mat-divider>
            </h3>
          </div>
          @if (CustomerViewData.user === null) {
            <span > Not Available</span>
          }
          <div fxFlex="100">
            @if (CustomerViewData.user !== null) {
              <div fxFlex="50">
                <span>User Status:</span>
                <span>{{CustomerViewData.user.status.value}}</span>
              </div>
            }
            @if (CustomerViewData.user!== null) {
              <div fxFlex="50">
                <span>Registration Date:</span>
                <span>{{CustomerViewData.user.createdDate|date : 'short' : uiTimezone}}</span>
              </div>
            }
          </div>
          @if (CustomerViewData.user !== null) {
            <div fxFlex="100">
              <div fxFlex="50">
                <span>Web Account Locked:</span>
                <span>{{CustomerViewData.locked == true ? 'Yes':'No'}}</span>
              </div>
              <div fxFlex="50">
                <span>Last Login Date:</span>
                <span>{{CustomerViewData.user.loginTryDate|date : 'short' : uiTimezone}}</span>
              </div>
            </div>
          }
          <!-- <div fxFlex="100" class="pt-2" *ngIf="CustomerViewData.user !== null">
          <div fxFlex="50">
            <span>Last Login IP:</span>
            <span>{{CustomerViewData.user.lastLoginIP}}</span>
          </div>
        </div> -->
      </div>
      <div fxLayout="column">
        <div fxLayout="column">
          <h4>
            Portal Access
            <mat-divider></mat-divider>
          </h4>
        </div>
        <div>
          @if (CustomerViewData.user !== null) {
            <span>
              @for (role of CustomerViewData.user.authorities; track role) {
                <span>My LondonHydro ({{role.roleCode}})</span>
              }
            </span>
          }
          @if (CustomerViewData.user === null) {
            <span>Not Yet Registered</span>
          }
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="column">
          <h4>
            Actions
            <mat-divider></mat-divider>
          </h4>
        </div>
      </div>
      <mat-card-footer fxLayoutAlign="space-evenly center">
        <button class="btn btn-primary" [disabled]="CustomerViewData.user == null || CustomerViewData.user.status.value =='Active'">
        Send Registration Link</button>
        <button class="btn btn-danger" [disabled]="CustomerViewData.user == null">Change Email</button>
        <button class="btn btn-danger" [disabled]="CustomerViewData.user == null">Block Account</button>
        <button class="btn btn-primary" [disabled]="CustomerViewData.user == null">Unblock Account</button>
        <button class="btn btn-danger" [disabled]="CustomerViewData.user == null">Close Account</button>
      </mat-card-footer>
    </mat-card-content>
  } @else {
    <mat-dialog-content> No Data Found.</mat-dialog-content>
  }
</mat-card>
}