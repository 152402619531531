<div id="modal-content-wrapper">
  <section id="modal-body">
    <mat-card appearance="outlined" class="ux-card border border-light p-0">
      <mat-card-header class="bg-light p-2 pt-3" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title>Uploaded Files</mat-card-title>
        <button (click)="closeModal()" class="btn btn-link text-secondary" type="button" id="dismiss"
          aria-label="Close">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
      </mat-card-header>
      @if (fileCategories && fileCategories.length > 0) {
        <mat-card-content fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px" class="p-4"
          >
          @for (category of fileCategories; track category) {
            <div>
              <app-file-upload fxFlex="100" [uploadConfig]="fileViewerConfig[category]" [mode]="viewMode"
              [documentRef]="fileListWithCategory[category]"></app-file-upload>
            </div>
          }
        </mat-card-content>
      } @else {
        <mat-card-content fxLayout="column" fxLayoutAlign="center center" class="p-4">
          <div>
            <p class="font-weight-bold text-info">There are no files uploaded for the selected form.</p>
          </div>
        </mat-card-content>
      }
    </mat-card>
  </section>
</div>
