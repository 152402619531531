<mat-card appearance="outlined" fxLayout="column">
  <mat-card-header>
    <h2>Edit Profile</h2>
    <mat-icon class="dialog-close-button">close</mat-icon>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="updateForm"  fxflex="100" fxLayout="column" fxLayoutGap="20px">
      <div fxFlex="100" fxLayout="row"  fxLayout_xs="column">
        <div fxFlex="50">
          <mat-label>Login Email:</mat-label>
        </div>
        <div fxFlex="50">
          {{this.username}}
        </div>
      </div>
      <div fxFlex="100" fxLayout="row"  fxLayout_xs="column">
        <div fxFlex="50">
          <mat-label>First Name:</mat-label>
        </div>
        <div fxFlex="50" fxFlex_xs="100">
          <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="firstName" required>
          </mat-form-field>
          @if (updateForm.hasError('firstName') && !updateForm.hasError('required')) {
            <mat-error>
              Please enter a valid name
            </mat-error>
          }
          @if (updateForm.hasError('required')) {
            <mat-error>
              First Name is Required
            </mat-error>
          }
        </div>
      </div>
      <div fxFlex="100" fxLayout="row"  fxLayout_xs="column">
        <div fxFlex="50">
          <mat-label>Last Name:</mat-label>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="lastName" required>
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="100" fxLayout="row"  fxLayout_xs="column">
        <div fxFlex="50">
          <mat-label>Phone Number:</mat-label>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline">
            <input matInput type="tel" placeholder="(999) 999-9999" formControlName="phoneNumber" required>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-footer fxLayoutAlign="end end" fxLayoutGap="20px">
    <button class="btn btn-danger" (click)="closeMe()">
    Cancel</button>
    <button class="btn btn-success"
      (click)="updateMyProfile();" [disabled]="updateForm.invalid">
    Update</button>
  </mat-card-footer>

</mat-card>