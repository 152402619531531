<div id="modal-content-wrapper">
  <section id="modal-body">
    <mat-card appearance="outlined" class="ux-card border border-light p-0">
      <mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title>Service Request Details</mat-card-title>
        <button (click)="closeModal()" class="btn btn-link text-secondary" type="button" id="dismiss"
          aria-label="Close">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
      </mat-card-header>

      <mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-accordion class="border border-light p-0">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
              fxLayoutAlign="space-between center">
              <mat-panel-title class="mb-0 text-white font-weight-bold">Request Details</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
              @for (attribute of (formAttributeConfig | listFilter: 'group': 'request'); track attribute) {
                <div fxFlex="50" fxFlex_lt-sm="100" fxLayout="row" fxLayoutAlign="space-between center"
                  >
                  <div fxFlex="40" fxFlex_lt-sm="40" fxFlex_lt-md="25" class="p-2">{{attribute.label}}:
                  </div>
                  <div fxFlex="60" fxFlex_lt-sm="60" fxFlex_lt-md="75"
                    class="p-2 font-weight-bold text-break"
                    [ngClass]="(attribute.class || '') + ' ' + (attribute.dataClass? formDetails[attribute.dataValue] : '')">
                    @if (attribute.type === 'string' && !attribute.translate) {
                      <span
                      >{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
                    }
                    @if (attribute.type === 'string' && attribute.translate) {
                      <span
                        >{{formDetails.getDeepData(attribute.name) | labelTranslate: 'cis'}}
                      </span>
                    }
                    @if (attribute.type === 'formDisplayName' && !isFormOriginMyaccount) {
                      <span
                        >{{formDetails.getDeepData(attribute.name)}}
                      </span>
                    }
                    @if (attribute.type === 'formDisplayName' && isFormOriginMyaccount) {
                      <span>
                        <span>{{attribute.exceptionalFormToTranslate === formDetails.getDeepData('form.name')?(formDetails.getDeepData('form.name') | labelTranslate) : formDetails.getDeepData(attribute.name)}}</span>
                      </span>
                    }
                    @if (attribute.type === 'number') {
                      <span
                      >{{formDetails.getDeepData(attribute.name) | number: '1.0-2'}}</span>
                    }
                    @if (attribute.type === 'date') {
                      <span
                      >{{formDetails.getDeepData(attribute.name) ? (formDetails.getDeepData(attribute.name) | date: 'short' : uiTimezone ) : (attribute.default || '-')}}</span>
                    }
                    @if (attribute.type === 'shortDate') {
                      <span
                      >{{formDetails.getDeepData(attribute.name) ? (formDetails.getDeepData(attribute.name) | date: 'shortDate' : uiTimezone ) : (attribute.default || '-')}}</span>
                    }
                    @if (attribute.type === 'hidden') {
                      <span
                      class="d-none">{{formDetails.getDeepData(attribute.name)}}</span>
                    }
                    @if (attribute.type === 'assignedUser') {
                      <span
                      >{{formDetails.getDeepData(attribute.name) && formDetails.getDeepData('assignedTo')?formDetails.getDeepData(attribute.name):(formDetails.getDeepData('assignedUser.userName') && formDetails.getDeepData('assignedTo')?formDetails.getDeepData('assignedUser.userName'):attribute.default)}}</span>
                    }
                  </div>
                </div>
              }
            </mat-card-content>
          </mat-expansion-panel>
        </mat-accordion>

        <!-- <mat-accordion class="border border-light p-0 mt-2">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
            fxLayoutAlign="space-between center">
            <mat-panel-title class="mb-0 text-white font-weight-bold">User/Customer Details</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
            <div [fxFlex]="attribute.width?attribute.width:50" [fxFlex_lt-sm]="100" fxLayout="row"
              fxLayoutAlign="space-between center"
              *ngFor="let attribute of (formAttributeConfig | listFilter: 'group': 'form')"
              [fxHide]="!formDetails.getDeepData(attribute.name)">
              <div [fxFlex]="attribute.width==100?15:40" [fxFlex_lt-sm]="40" [fxFlex_lt-md]="25"
                class="p-2">{{attribute.label}}:
              </div>
              <div [fxFlex]="attribute.width==100?85:60" [fxFlex_lt-sm]="60" [fxFlex_lt-md]="75"
                class="p-2 font-weight-bold text-break">
                <span
                *ngIf="attribute.type === 'string'">{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
                <span
                *ngIf="attribute.type === 'number'">{{masterForm[attribute.name] | number: '1.0-2'}}</span>
                <span
                *ngIf="attribute.type === 'date'">{{masterForm[attribute.name] | date: 'short' : uiTimezone }}</span>
                <span
                *ngIf="attribute.type === 'shortDate'">{{masterForm[attribute.name] | date: 'shortDate' : uiTimezone }}</span>
                <span *ngIf="attribute.type === 'hidden'"
                class="d-none">{{masterForm[attribute.name]}}</span>

              </div>

            </div>
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion> -->

      <mat-accordion class="border border-light p-0 mt-2">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
            fxLayoutAlign="space-between center">
            <mat-panel-title class="mb-0 text-white font-weight-bold">Modification Details
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch" class="mb-0">
            @for (attribute of (formAttributeConfig | listFilter: 'group': 'cis'); track attribute) {
              <div fxFlex_lt-sm="100" fxFlex="100"
                [hidden]="!formDetails.getDeepData(attribute.name)">
                <div fxLayout="row" [hidden]="!formDetails.getDeepData(attribute.name)">
                  @if (formDetails.getDeepData(attribute.name) ) {
                    <app-additional-cis-data-viewer [requestDetails]="formDetails.requestDetails"
                      fxLayout="row" fxFlex="100"
                    fxLayoutAlign="space-between stretch"></app-additional-cis-data-viewer>
                  }
                </div>
              </div>
            }
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion>


      <!-- <mat-accordion class="border border-light p-0 mt-2" *ngIf="isPaymentTermsSectionRequired()">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
          fxLayoutAlign="space-between center">
          <mat-panel-title class="mb-0 text-white font-weight-bold">Payment Terms
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-card-content fxLayout="row wrap" class="mb-0" fxLayoutAlign="space-between center">
          <div fxLayout="row" [class]=" attribute.class "
            *ngFor="let attribute of (formAttributeConfig | listFilter: 'group': 'paymentTerms')"
            [fxFlex]="attribute.type === 'array'?100:50" [fxHide]="!formDetails.getDeepData(attribute.name)" fxFlex_xs="100">
            <div fxLayout="row" fxFlex="100"
              fxLayoutAlign="space-between center">
              <div fxFlex="35" *ngIf="attribute.type !== 'array'" fxFlex_lt-sm="40" [fxFlex_lt-md]="25" class="p-2">
              {{attribute.label}}:</div>
              <div fxFlex="65" *ngIf="attribute.type !== 'array'"  fxFlex_lt-sm="60" [fxFlex_lt-md]="75"
                class="p-2 font-weight-bold text-break"
                [ngClass]="(attribute.class || '') + ' ' + (attribute.dataClass? formDetails[attribute.dataValue] : '')">
                <span *ngIf="attribute.type === 'string' && !attribute.translate">{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
                <span *ngIf="attribute.type === 'currency'">{{formDetails.getDeepData(attribute.name)  | currency:'USD':'symbol'}}</span>

              </div>
              <div fxFlex="100" *ngIf="attribute.type === 'array'"
                class="text-break p-2"
                [ngClass]="(attribute.class || '') + ' ' + (attribute.dataClass? formDetails[attribute.dataValue] : '')">

                <div fxFlex="100"  fxLayout="column" *ngIf="attribute.type === 'array'" fxLayoutGap="10px">
                  <div fxFlex="100" fxLayout="row" fxLayout_xs="column" [class]="i===formDetails['paymentInstallments'].length-1  && formDetails.getDeepData('totalAmount') ?' '+'border-btm'+' ':''" *ngFor="let item of formDetails['paymentInstallments'];index as i">
                    <span [fxFlex]="33" fxFlex_xs="100" *ngFor="let subAttribute of attribute.subAttributeListConfig">
                      <span fxFlex="60" fxFlex_xs="100" class="font-weight-light">{{subAttribute.label}} &nbsp;{{'#'+(i+1)}}:&nbsp;</span>
                      <span fxFlex="40" fxFlex_xs="100" class="font-weight-bold">
                        <span *ngIf="subAttribute.type==='currency'">{{item[subAttribute.name]  | currency:'USD':'symbol' || '-'}}</span>
                        <span *ngIf="subAttribute.type==='string'">{{item[subAttribute.name] || '-'}}</span>
                        <span *ngIf="subAttribute.type==='date'">{{item[subAttribute.name] | date: 'short' : uiTimezone || '-'}}</span>
                        <span *ngIf="subAttribute.type==='shortDate'">{{item[subAttribute.name] | date: 'shortDate' : uiTimezone || '-'}}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-expansion-panel>
    </mat-accordion> -->

    <!-- <mat-accordion class="border border-light p-0 mt-2">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
        fxLayoutAlign="space-between center">
        <mat-panel-title class="mb-0 text-white font-weight-bold">User/Customer Details</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
        <div [fxFlex]="attribute.width?attribute.width:50" [fxFlex_lt-sm]="100" fxLayout="row"
          fxLayoutAlign="space-between center"
          *ngFor="let attribute of (formAttributeConfig | listFilter: 'group': 'form')"
          [fxHide]="!formDetails.getDeepData(attribute.name)">
          <div [fxFlex]="attribute.width==100?15:40" [fxFlex_lt-sm]="40" [fxFlex_lt-md]="25"
            class="p-2">{{attribute.label}}:
          </div>
          <div [fxFlex]="attribute.width==100?85:60" [fxFlex_lt-sm]="60" [fxFlex_lt-md]="75"
            class="p-2 font-weight-bold text-break">
            <span
            *ngIf="attribute.type === 'string'">{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
            <span
            *ngIf="attribute.type === 'number'">{{masterForm[attribute.name] | number: '1.0-2'}}</span>
            <span
            *ngIf="attribute.type === 'date'">{{masterForm[attribute.name] | date: 'shortDate': uiTimezone}}</span>
            <span *ngIf="attribute.type === 'hidden'"
            class="d-none">{{masterForm[attribute.name]}}</span>

          </div>

        </div>
      </mat-card-content>
    </mat-expansion-panel>
  </mat-accordion> -->

  <mat-accordion class="border border-light p-0 mt-2">
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
        fxLayoutAlign="space-between center">
        <mat-panel-title class="mb-0 text-white font-weight-bold">Uploaded Files</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card-content class="mb-0">
        @if (fileCategories && fileCategories.length > 0) {
          <div
            fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px" class="p-4">
            @for (category of fileCategories; track category) {
              <div>
                <app-file-upload fxFlex="100" [uploadConfig]="fileViewerConfig[category]"
                  [mode]="viewMode" [documentRef]="fileListWithCategory[category]">
                </app-file-upload>
              </div>
            }
          </div>
        } @else {
          <div fxLayout="column" fxLayoutAlign="center start" class="p-4">
            <p class="font-weight-bold text-secondary">There are no files uploaded for the
            selected form.</p>
          </div>
        }
      </mat-card-content>
    </mat-expansion-panel>
  </mat-accordion>


  <mat-accordion class="border border-light p-0 mt-2">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
        fxLayoutAlign="space-between center">
        <mat-panel-title class="mb-0 text-white font-weight-bold">Generated Alerts</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card-content fxLayout="row" fxLayoutAlign="space-between center" class="mb-0">
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
          <div fxFlex="100" fxHide_lt-sm fxShow fxLayout="row"
            fxLayoutAlign="space-between center" class="border-bottom border-secondary">
            <div fxFlex="10" fxFlex_lt-md="30" class="p-2 font-weight-bold">
              <span>#</span>
            </div>
            <div fxFlex="65" fxFlex_lt-md="40" class="p-2 font-weight-bold">
              <span>Message/Alert</span>
            </div>
            <div fxFlex="25" fxFlex_lt-md="30" class="p-2 font-weight-bold">
              <span>Generated On</span>
            </div>
          </div>
          @if (requestAlerts && requestAlerts.length > 0) {
            <div fxFlex="100" fxLayout="row wrap"
              >
              @for (alert of requestAlerts; track alert; let i = $index) {
                <div fxFlex="100" fxLayout="row" fxLayout_lt-sm="column"
                  [ngStyle]="{'border-bottom' : screensize_ltsm ? 'double' : null}" fxLayoutAlign="space-between center"
                  >
                  <div fxFlex="10" fxFlex_lt-sm="100" fxFlex_lt-md="30" class="p-2"
                    [ngClass]="{'mr-auto' : screensize_ltsm}">
                    <span fxShow_lt-sm fxHide class="font-weight-bold "># </span>
                    <span>{{alert.serialNumber}}</span>
                  </div>
                  <div fxFlex="65" fxFlex_lt-sm="100" fxFlex_lt-md="40" class="p-2" fxLayout="row"
                    [ngClass]="{'mr-auto' : screensize_ltsm}">
                    <span fxShow_lt-sm fxHide
                    class="font-weight-bold ">Message/Alert</span>
                    <div [class]="'bg-severity-' + alert.severity" class="mr-1">
                      @if (alert.severity != 'L' && alert.severity != 'S') {
                        <svg class="bi flex-shrink-0 me-2" width="20" height="20"
                          >
                        <use xlink:href="#exclamation-triangle-fill" /></svg>
                      }
                      @if (alert.severity === 'L') {
                        <svg class="bi flex-shrink-0 me-2" width="20" height="20"
                          >
                        <use xlink:href="#info-fill" /></svg>
                      }
                      @if (alert.severity === 'S') {
                        <svg class="bi flex-shrink-0 me-2" width="20" height="20"
                          >
                        <use xlink:href="#check-circle-fill" /></svg>
                      }
                    </div>
                    <span>{{alert.message}}</span>
                  </div>
                  <div fxFlex="25" fxFlex_lt-sm="100" fxFlex_lt-md="30" class="p-2"
                    [ngClass]="{'mr-auto' : screensize_ltsm}">
                    <span fxShow_lt-sm fxHide class="font-weight-bold ">Generated
                    On</span>
                    <span>{{alert.createdDate | date: 'short': uiTimezone}}</span>
                  </div>
                </div>
              }
            </div>
          } @else {
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
              <p class="font-weight-bold text-secondary">There are no alerts generated for the
              request.</p>
            </div>
          }
        </div>
      </mat-card-content>
    </mat-expansion-panel>
  </mat-accordion>

</mat-card-content>
</mat-card>
</section>
</div>