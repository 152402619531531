<div fxFlex="100" fxLayout="column">
<div fxLayoutAlign="center center">
<div fxHide_xs fxHide_sm fxShow fxFlex="100" fxLayout="row" fxLayoutAlign="center flex-start" [class]="'banner-container '+' banner-container-img-'+dynamicBannerClass" id="bannerContainer" #bannerContainer>
    <div class="banner-content overlay">
        <uxlib-banner fxFlex="100" [floatingMenuItems]="floatingMenuItems"></uxlib-banner>
    </div>
</div>
<div fxShow_xs fxShow_sm fxHide fxFlex="100">
    <uxlib-banner fxFlex="100" [floatingMenuItems]="floatingMenuItems"></uxlib-banner>
</div>
</div>
<uxlib-navigation-panel [navigationMenuItems]="navigationMenuItems" [stickyMenuItems]="stickyMenuItems"
     [isNavPanelSticky]="isNavPanelSticky"></uxlib-navigation-panel>
</div>