<mat-card appearance="outlined" fxLayout="column">
  <mat-card-header>
    <h2>Change Account Password</h2>
  </mat-card-header>
  <mat-icon class="dialog-close-button">close</mat-icon>
  <mat-card-content>
    <form fxflex="100" fxLayout="column" fxLayoutGap="20px" [formGroup]="passwordChangeForm">
      <div fxLayout="column">
        <div fxLayoutAlign="end end">*required</div>
        <div fxLayout="row" fxFlex="100"  fxLayout_xs="column">
          <div fxFlex="50">
            <mat-label for="login-email">Login Email:</mat-label>
          </div>
          <div fxFlex="50">
            <h4> {{this.user.username}}</h4>
          </div>
        </div>
        <div fxLayout="row" fxFlex="100"  fxLayout_xs="column">
          <div fxFlex="50">
            <mat-label for="current-password">Current Password: *</mat-label>
          </div>
          <div fxFlex="50">
            <mat-form-field appearance="outline">
              <input matInput type="password" id="current-password" formControlName="oldPassword" minlength="6"
                maxlength="30" required>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxFlex="100"  fxLayout_xs="column">
          <div fxFlex="50">
            <mat-label for="new-password">New Password: *</mat-label>
          </div>
          <div fxFlex="50">
            <mat-form-field appearance="outline">
              <input matInput type="password" id="new-password" formControlName="newPassword" maxlength="30" required>
            </mat-form-field>
            @if (passwordChangeForm.controls['newPassword'].invalid && (passwordChangeForm.controls['newPassword'].dirty || passwordChangeForm.controls['newPassword'].touched)) {
              <div
                class="text-danger">
                @if (passwordChangeForm.controls['newPassword'].errors?.required) {
                  <div>
                    Password is required.
                  </div>
                }
                @else if (passwordChangeForm.controls['newPassword'].errors?.minlength) {
                  <div>
                    Password be at least 6 characters long.
                  </div>
                }
                @else {
                  <div>
                    Password must follow the pattern.
                  </div>
                }
              </div>
            }
          </div>
        </div>
        <div fxFlex="100" fxLayoutAlign="start stretch">
          <small>
            (Password must be a minimum of 6 characters, maximum of 30 characters and must use both upper and lower case
            letters and at least one number.)
          </small>
        </div>
        <div fxLayout="row" fxFlex="100"  fxLayout_xs="column">
          <div fxFlex="50">
            <mat-label for="repeat-password"> Repeat Password: *</mat-label>
          </div>
          <div fxFlex="50">
            <mat-form-field appearance="outline">
              <input matInput type="password" id="repeat-password" formControlName="repeatPassword" required>
            </mat-form-field>
            @if (passwordChangeForm.controls['repeatPassword'].invalid && (passwordChangeForm.controls['repeatPassword'].dirty || passwordChangeForm.controls['repeatPassword'].touched)) {
              <div
                class="text-danger">
                @if (passwordChangeForm.controls['repeatPassword'].errors?.required) {
                  <div>
                    Field is required.
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-footer fxLayoutGap="20px" fxLayoutAlign="end end">
    <button class="btn btn-danger">Cancel</button>
    <button class="btn btn-success" [disabled]="!passwordChangeForm.valid || submitted"
      (click)="changeAccountPassword()" [ngClass]="{ 'disabled': (!passwordChangeForm.valid || submitted) }">
    Update</button>
  </mat-card-footer>

</mat-card>