@if (searchForm) {
<mat-card class="custom-mat-mdc-card">
  <!-- <mat-card-header>
    <mat-card-title>Customer Lookup</mat-card-title>
  </mat-card-header> -->
  <mat-card-content class="content-body p-0" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="2.69rem">
    <div class="search-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="1.38rem">
      <div fxFlex="100" fxLayout="row" [class]="this.valueIsDuplicate?'pb-4':''">
        <mat-label id="top-label" class="font-weight-bold float-left text-dark">Search by Customer
          Number, Account Number or Email Address:
        </mat-label>
      </div>
      <div fxFlex="100" [formGroup]="searchForm" fxLayout="row">
        <mat-form-field appearance="outline" class="w-100 search-field">
          <input type="search" matInput id="searchKey" (keydown.enter)="search()" formControlName="searchKey"
            required>
          <div matSuffix fxLayout="row" fxLayoutGap="2px" fxLayoutAlign="end center" class="search-suffix">
            <button matSuffix mat-icon-button aria-label="Clear" (click)="searchForm.controls.searchKey.setValue(null)"
            style="width:70px !important; height:70px !important;">
            <img style="width:45px !important; height:45px !important;" src="assets/images/remove.png">
          </button>
          <button matSuffix type="button" class="btn btn-sm ux-icon-button search-btn" title="Search" (click)="search(false, true)"
            fxLayout="row" fxLayoutAlign="start center"
            [disabled]="searchForm.invalid || searchForm.errors?.fieldInavlid">
            <span>Search</span>
          </button>
          </div>
          
        </mat-form-field>
        <!-- <mat-error *ngIf="this.valueIsDuplicate">
              Please enter a new value, search result is currently displayed for this search value
            </mat-error> -->
        <!-- <div [class]="this.valueIsDuplicate?'pb-4':''"> -->
        <!-- <button type="button" class="btn btn-sm ux-icon-button" title="Search" (click)="search(false, true)"
            fxLayout="row" fxLayoutAlign="start center"
            [disabled]="searchForm.invalid || searchForm.errors?.fieldInavlid">
            <mat-icon data-toggle="tooltip" data-placement="bottom" title="Search" class="mr-2">
            search</mat-icon>
            <span>Search</span>
          </button> -->
        <!-- </div> -->
      </div>
      <!-- <div fxFlexOffset="50" fxFlex="50">
      <a href="{{myaccountUrl}}/myaccount.html" target="newmyaccount">New Customer(New window)</a>
    </div> -->
    </div>
    @if (isError == true) {
    <div>
      <mat-error>
        <h2>Your search did not match any customer or user.</h2>
        <p> Suggestions:</p>
        <ul>
          <li>Make sure that all words are spelled correctly</li>
          <li>Try different keywords</li>
          <li>Try more general keywords</li>
          <li>Try fewer keywords</li>
        </ul>
      </mat-error>
    </div>
    }
    @if(showContent){
    <mat-card class="cust-lookup-container">
      <mat-card-content fxLayout="column" fxLayoutGap="2rem">
       <!--  <mat-card class="cust-lookup-child-container p-0">
          <mat-card-content fxLayout="column" fxLayoutGap="2rem"> -->
            <mat-accordion class="border border-light p-0 cust-lookup-accordion">
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header class="bg-red px-3 py-2" fxLayout="row" 
                  fxLayoutAlign="space-between center" fxLayoutGap_xs="10px">
                  <mat-panel-title class="mb-0 text-white">Customer Details</mat-panel-title>
                </mat-expansion-panel-header>
                @if (customerInfo) {
                <mat-card-content fxLayout="column" class="p-0">
                  <div fxLayout="row" fxLayout_xs="column" fxLayoutGap="1.375rem" class="cust-info-container">
                    <div fxFlex="50" fxFlex_xs="100" fxLayout="row" fxLayout_xs="column">
                      <div class="title">Customer ID :&nbsp;</div>
                      <div class="text-break value">
                        <span>{{customerInfo.id || '-'}}</span>
                      </div>
                    </div>
                    <div fxFlex="50" fxFlex_xs="100" fxLayout="row" fxLayout_xs="column">
                      @if (customerInfo.customerType =='P') {
                      <div class="title">Full Name :&nbsp;</div>
                      }
                      @if (customerInfo.customerType =='O') {
                      <div class="title">Customer Name :&nbsp;</div>
                      }
                      <div class="text-break value">
                        @if (customerInfo.customerType =='P') {
                        <span>{{customerInfo.fullName}}</span>
                        }
                        @if (customerInfo.customerType =='O') {
                        <span>{{customerInfo.organization}}</span>
                        }
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayout_xs="column">
                    <div fxFlex="50" fxFlex_xs="100" fxLayout="column">
                      <div fxFlex="100" class="email-header header white-sp-responsive font-weight-bold bg-grey"
                        fxLayoutAlign="flex-start center">Email(s) :</div>
                      <div fxFlex="100" class="email-value text-break">
                        @if (customerInfo.emails && customerInfo.emails.length>0) {
                        <div class="">
                          @if (customerInfo.emails.length==1) {
                          <span>{{customerInfo.emails[0].emailAddress}}</span>
                          }
                          @if (customerInfo.emails.length>1) {
                          <ul class="p-0">
                            @for (email of customerInfo.emails; track email; let i = $index) {
                            <li class="font-weight-bold mb-1">
                              @if (customerInfo.emails.length>1) {
                              <span>{{email.emailAddress}}
                                {{email.standard ? ' (Standard)' : ''}}</span>
                              }
                            </li>
                            }
                          </ul>
                          }
                        </div>
                        }
                        @if (!customerInfo.emails || customerInfo.emails.length == 0) {
                        <span> - </span>
                        }
                      </div>
                    </div>
                    <div fxFlex="50" fxFlex_xs="100" fxLayout="column">
                      <div fxFlex="100" class="mailing-addr-header header bg-grey font-weight-bold" fxLayoutAlign="flex-start center">
                        Mailing Address</div>
                      <div fxFlex="100" class="mailing-addr-value text-break">
                        @if (customerInfo.mailingAddresses && customerInfo.mailingAddresses.length > 0) {
                        <div class="mb-0">
                          @if (customerInfo.mailingAddresses.length==1) {
                          <span>
                            {{customerInfo.mailingAddresses[0].fullAddress}}</span>
                          }
                          @if (customerInfo.mailingAddresses.length>1) {
                          <ul style="margin-left:-40px">
                            @for (mailingAddress of customerInfo.mailingAddresses; track mailingAddress; let i = $index)
                            {
                            <li class="font-weight-bold">
                              {{spaceReplaced(mailingAddress.fullAddress)}}
                              <span>{{(mailingAddress.fullAddress && mailingAddress.standard) ? ' (Standard)' :
                                ''}}</span>
                            </li>
                            }
                          </ul>
                          }
                        </div>
                        }
                        @if (!customerInfo.mailingAddresses || customerInfo.mailingAddresses.length == 0) {
                        <span>-</span>
                        }
                      </div>
                    </div>
                  </div>

                  <div fxLayout="row" fxLayout_xs="column">
                    <div fxFlex="100" fxLayout="column">
                      <div fxFlex="100" class="phone-no-header header white-sp-responsive font-weight-bold bg-grey"
                        fxLayoutAlign="flex-start center">Phone Number(s) :</div>
                      <div fxFlex="100" class="phone-no-value">
                        @if (customerInfo && customerInfo.phoneNumbers) {
                        <ul class="p-0 phone-numbers-list">
                          @for (category of phoneCategories; track category) {
                          <div fxLayout="column">
                            @if (hasPhoneCategory(category.category)) {
                            <div fxLayout="row">
                              @if (getPhoneNumbers(category.category).length > 0) {
                              <li class="title">{{category.label + ':'}}&nbsp; </li>
                              }
                              <div class="value">
                                @for (phnNumber of (getPhoneNumbers(category.category) | subListFilter: 3); track
                                phnNumber; let i = $index) {
                                <span>@if (i>0) {
                                  <span>, </span>
                                  }
                                  {{phnNumber.phoneNumber ? formatPhnNumber(phnNumber.phoneNumber) : '-' }}</span>
                                }
                                @if (getPhoneNumbers(category.category).length > 3) {
                                <span class="sublist"> (3 of
                                  {{getPhoneNumbers(category.category).length}})</span>
                                }
                              </div>
                            </div>
                            }
                          </div>
                          }
                        </ul>
                        }
                        @if (!customerInfo.phoneNumbers || !customerInfo) {
                        <div>
                          <span>{{'-'}}</span>
                        </div>
                        }
                      </div>
                      <!-- <div fxFlex="70" class="p-2 font-weight-bold text-break" * ngIf="false">
                                    <div *ngIf="customerInfo?.businessNumbers?.length==1 &&
                                    customerInfo?.residentialNumbers?.length<1 && customerInfo?.cellNumbers?.length<1 &&
                                    customerInfo?.notificationNumbers?.length<1">
                                         <span>Business: </span>{{customerInfo.businessNumbers[0].phoneNumber || '-'}}
                                        {{customerInfo.businessNumbers[0].standard ? ' (Standard)' : ''}}
                                    </div>
                                    <div *ngIf="customerInfo?.businessNumbers?.length<1 &&
                                    customerInfo?.residentialNumbers?.length==1  && customerInfo?.cellNumbers?.length<1 &&
                                    customerInfo?.notificationNumbers?.length<1">
                                        <span>Residential: </span>{{customerInfo.residentialNumbers[0].phoneNumber || '-'}}
                                        {{customerInfo.residentialNumbers[0].standard ? ' (Standard)' : ''}}
                                    </div>
                                    <div *ngIf="customerInfo?.businessNumbers?.length<1 &&
                                    customerInfo?.residentialNumbers?.length<1  && customerInfo?.cellNumbers?.length==1 &&
                                    customerInfo?.notificationNumbers?.length<1">
                                        <span>cell: </span>{{customerInfo.cellNumbers[0].phoneNumber || '-'}}
                                        {{customerInfo.cellNumbers[0].standard ? ' (Standard)' : ''}}
                                    </div>
                                    <div *ngIf="customerInfo?.businessNumbers?.length<1 &&
                                    customerInfo?.residentialNumbers?.length<1  && customerInfo?.cellNumbers?.length<1 &&
                                    customerInfo?.notificationNumbers?.length==1">
                      <span>Notification: </span>{{customerInfo.notificationNumbers[0].phoneNumber || '-'}}
                      {{customerInfo.notificationNumbers[0].standard ? ' (Standard)' : ''}}
                    </div>
                                    <ul *ngIf="customerInfo.phoneNumbers
                                    && (customerInfo?.businessNumbers?.length>1 ||
                                    customerInfo?.residentialNumbers?.length>1  || customerInfo?.cellNumbers?.length>1 ||
                                    customerInfo?.notificationNumbers?.length>1) " class="mb-0" style="margin-left:-40px">

                                        <li *ngFor="let phNum of customerInfo.businessNumbers" class="font-weight-bold">
                                            <span>Business: </span>{{phNum.phoneNumber || '-'}}
                                            {{phNum.standard ? ' (Standard)' : ''}}</li>
                                        <li *ngFor="let phNum of customerInfo.residentialNumbers" class="font-weight-bold">
                                            <span>Residential: </span>{{phNum.phoneNumber || '-'}}
                                            {{phNum.standard ? ' (Standard)' : ''}}</li>
                                        <li *ngFor="let phNum of customerInfo.cellNumbers" class="font-weight-bold">
                                            <span>Cell: </span>{{phNum.phoneNumber || '-'}}
                                            {{phNum.standard ? ' (Standard)' : ''}}</li>
                                        <li *ngFor="let phNum of customerInfo.notificationNumbers" class="font-weight-bold">
                                            <span>Notification: </span>{{phNum.phoneNumber || '-'}}
                                            {{phNum.standard ? ' (Standard)' : ''}}</li>
                                    </ul>
                                    <span *ngIf="!customerInfo.phoneNumbers">-</span>
                                </div> -->
                    </div>
                  </div>

                  @if (customerList && customerList.length > 1) {
                  <div fxFlex="100" fxLayout="row" fxLayout_xs="column" class="disclaimer text-danger">
                    <mat-icon class="align mat-tooltip-panel" matTooltipPosition="below">warning</mat-icon>
                    <span> Note - Multiple customer ID&nbsp;</span>
                    (
                    @for (info of customerList; track info; let i = $index) {
                    <span>{{(i < (customerList.length-1) ? info.id + ',' : info.id) || '-' }} </span>
                        }
                        )
                        <span>&nbsp;mapped to this email. Please search with these Customer IDs to get details of each
                          of them.</span>
                  </div>
                  }
                </mat-card-content>
                }
                @if (!customerInfo) {
                <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
                  <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" class="no-data text-break">
                    <span>Not Linked with any Customer</span>
                  </div>
                </mat-card-content>
                }
              </mat-expansion-panel>
            </mat-accordion>
            @if (showContent && customerInfo && customerInfo.childCustomers && customerInfo.childCustomers.length>0) {
            <mat-accordion class="border border-light p-0 mt-2 cust-lookup-accordion">
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header class="bg-red p-2" fxLayout="row" fxLayoutAlign="space-between center"
                  fxLayoutGap_xs="10px">
                  <mat-panel-title class="mb-0 text-white">Child Customer Details</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="toggle-section" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="end end">
                  @if (state ==='list') {
                  <mat-icon matTooltip="Table view" matTooltipPosition="left"
                    (click)="onToggle('list')">view_stream</mat-icon>
                  }
                  @if (state ==='table') {
                  <mat-icon matTooltip="List view" matTooltipPosition="left"
                    (click)="onToggle('table')">view_list</mat-icon>
                  }
                </div>
                @if (customerInfo && state==='list') {
                <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between start" class="mb-0 m-2"
                  fxLayoutAlign_xs="start start" fxLayoutGap_xs="5px">
                  @for (childCustomer of customerInfo.childCustomers; track childCustomer; let i = $index) {
                  <div fxFlex="50" fxLayout="row" fxLayout_xs="column" fxLayoutAlign_xs="start space-between"
                    fxLayoutAlign="space-between center">
                    <div fxFlex="30" class="p-2">Customer ID :</div>
                    <div fxFlex="70" class="p-2 font-weight-bold text-break">
                      <span>{{childCustomer.id || '-'}}</span>
                    </div>
                  </div>
                  <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center" fxLayout_xs="column"
                    fxLayoutAlign_xs="start space-between">
                    @if (childCustomer.customerType =='P') {
                    <div fxFlex="30" class="p-2">Full Name :</div>
                    }
                    @if (childCustomer.customerType =='O') {
                    <div fxFlex="30" class="p-2">Customer Name :&nbsp;</div>
                    }
                    <div fxFlex="70" class="p-2 font-weight-bold text-break">
                      @if (childCustomer.customerType =='P') {
                      <span>{{childCustomer.fullName ? childCustomer.fullName : childCustomer.firstName + ' ' +
                        childCustomer.lastName}}</span>
                      }
                      @if (childCustomer.customerType =='O') {
                      <span>{{childCustomer.organization}}</span>
                      }
                    </div>
                  </div>
                  <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between start" fxLayout_lt-md="column"
                    fxLayoutAlign_xs="start space-between">
                    <div fxFlex="30" class="p-2 white-sp-responsive">Email(s) :&nbsp;</div>
                    <div fxFlex="70" class="p-2 font-weight-bold text-break">
                      @if (childCustomer.emails && childCustomer.emails.length>0) {
                      <div class="">
                        @if (childCustomer.emails.length==1) {
                        <span>{{childCustomer.emails[0].emailAddress}}</span>
                        }
                        @if (childCustomer.emails.length>1) {
                        <ul class="p-0">
                          @for (email of childCustomer.emails; track email; let i = $index) {
                          <li class="font-weight-bold">
                            @if (childCustomer.emails.length>1) {
                            <span>{{email.emailAddress}}
                              {{email.standard ? ' (Standard)' : ''}}</span>
                            }
                          </li>
                          }
                        </ul>
                        }
                      </div>
                      }
                      @if (!customerInfo.emails || customerInfo.emails.length == 0) {
                      <span> - </span>
                      }
                    </div>
                  </div>
                  <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between start" fxLayout_lt-md="column"
                    fxLayoutAlign_xs="start space-between">
                    <div fxFlex="100" class="p-2 font-weight-bold text-break">
                      <button mat-raised-button class="btn btn-sm ux-icon-button"
                        (click)="unlinkCustomer(childCustomer, customerInfo)" data-toggle="tooltip"
                        title="Link Customer">Unlink</button>
                    </div>
                  </div>
                  @if (i < customerInfo.childCustomers.length - 1) { <div fxFlex="100" fxLayout="column"
                    class="dashed-line-box">
                    </div>
                    }
                    }
                </mat-card-content>
                }
                @if (customerInfo && state==='table') {
                <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between start" class="mb-0 m-2"
                  fxLayoutAlign_xs="start start" fxLayoutGap_xs="5px">
                  @if (customerInfo && customerInfo.childCustomers) {
                  <mat-table [dataSource]="customerInfo.childCustomers" class="mat-elevation-z8">
                    <ng-container matColumnDef="customerId">
                      <mat-header-cell *matHeaderCellDef> Customer ID. </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.id || '-'}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        @if (element.customerType =='P') {
                        <span>{{element.fullName ? element.fullName : element.firstName + ' ' + element.lastName ||
                          '-'}}</span>
                        }
                        @if (element.customerType =='O') {
                        <span>{{element.organization || '-'}}</span>
                        }
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="email">
                      <mat-header-cell *matHeaderCellDef> Email(s) </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        @if (element.emails==null || element.emails.length==0) {
                        <span>-</span>
                        }
                        @if (element.emails && element.emails.length==1) {
                        <span>{{element.emails[0].emailAddress}}</span>
                        }
                        @if (element.emails && element.emails.length>1) {
                        <ul class="p-0">
                          @for (email of element.emails; track email; let i = $index) {
                          <li>
                            @if (element.emails.length>1) {
                            <span>{{email.emailAddress}}
                              {{email.standard ? ' (Standard)' : ''}}</span>
                            }
                          </li>
                          }
                        </ul>
                        }
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="unlink">
                      <mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        <button mat-raised-button class="btn btn-sm ux-icon-button"
                          (click)="unlinkCustomer(element, customerInfo)" data-toggle="tooltip"
                          title="Link Customer">Unlink</button>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                  }
                </mat-card-content>
                }
              </mat-expansion-panel>
            </mat-accordion>
            }
            <mat-accordion class="border border-light p-0 cust-lookup-accordion">
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header class="bg-red" fxLayout="row"
                  fxLayoutAlign="space-between center">
                  <mat-panel-title class="mb-0 text-white">Portal Access</mat-panel-title>
                </mat-expansion-panel-header>
                @if (userAccount !== null && !notYetRegistered) {
                <mat-card-content fxLayout="column" fxLayoutGap="10px" class="p-0">
                  <div fxLayout="row" fxLayout_xs="column" class="portal-mail-roles-container">
                    <div fxFlex="50" fxLayout="row" fxFlex_xs="100" fxLayout_xs="column">
                    <div class="login-title">Login Email :&nbsp;</div>
                    <div class="value text-break">
                      @if (userAccount && !notYetRegistered) {
                      <span>{{userAccount.username || '-'}}</span>
                      }
                      @if (userAccount && !notYetRegistered) {
                      <span>{{userAccount?.customerId ? ' ('+userAccount.customerId+')' : ''}}</span>
                      }
                      @if ((!userAccount && !customerInfo) || notYetRegistered) {
                      <span>Not Registered
                        Yet</span>
                      }
                    </div>
                  </div>
                  <div fxFlex="50" fxLayout="row" fxFlex_xs="100" fxLayout_xs="column">
                    <div class="portal-roles-title">Portals with Roles :&nbsp;</div>
                    <div class="value text-break">
                      @if (portals && portals.length>0) {
                      <div class="mb-0">
                        @if (portals.length==1) {
                        <span>{{portals}} ({{portalMap[portals]}})</span>
                        }
                        @if (portals.length>1) {
                        <ul>
                          @for (portal of portals; track portal) {
                          <li class="font-weight-bold"> {{portal}}
                            ({{portalMap[portal]}})</li>
                          }
                        </ul>
                        }
                      </div>
                      }
                      @if (!portals || portals.length==0) {
                      <div class="mb-0">
                        <span class="font-weight-bold text-break">Shell Account</span>
                      </div>
                      }
                    </div>
                  </div>
                  </div>


                </mat-card-content>
                }
                @if (userAccount !==null && !notYetRegistered) {
                <mat-card-content fxLayout="row wrap" class="p-0">
                  <div fxFlex="50" fxFlex_xs="100" fxLayout="row" fxLayout_xs="column" class="user-status-container wrap-bg-grey">
                    <div class="title">User Status :&nbsp;</div>
                    <div class="value text-break">
                      <span [class]="userAccount.status.value">
                        {{(('user.status.' + userAccount.status.value) | labelTranslate ) || '-'}} </span>
                      <span>
                        {{userAccount.status?.remark?.trim() ? '('+userAccount.status.remark+')' : ''}}
                      </span>
                    </div>
                  </div>
                  @if (userAccount.status.value && userAccount.status.value !== 'Active') {
                  <div fxFlex="50" fxFlex_xs="100" fxLayout="row" fxLayout_xs="column" class="account-status-container wrap-bg-grey">
                    <div class="title">{{(('user.statusdate.' + userAccount.status.value) | labelTranslate ) +
                      ':'&nbsp;|| '-'}}</div>
                    <div class="value text-break">
                      <span>{{(userAccount.status?.date | date : 'short') || '-'}}</span>
                    </div>
                  </div>
                  }
                  <div fxFlex="50" fxFlex_xs="100" fxLayout="row" fxLayout_xs="column" [ngClass]="userAccount.status.value && userAccount.status.value !== 'Active' ? '' : 'no-account-status'" class="web-acc-status-container wrap-bg-grey">
                    <div class="title">Web Account Locked :&nbsp;</div>
                    <div class="value text-break">
                      <mat-icon class="align mat-tooltip-panel"
                        matTooltip="{{userAccount.locked ? 'Locked' : 'Unlocked'}}" matTooltipPosition="below">
                        {{userAccount.locked ? 'lock' : 'lock_open'}}</mat-icon>
                    </div>
                  </div>
                  @if (userAccount.createdDate) {
                  <div fxFlex="50" fxFlex_xs="100" fxLayout="row" fxLayout_xs="column" [ngClass]="userAccount.status.value && userAccount.status.value !== 'Active' ? '' : 'no-account-status'" class="registration-container">
                    <div class="title">Registration Date :&nbsp;</div>
                    <div class="value text-break">
                      <span>{{(userAccount.createdDate | date : 'short') || '-'}}</span>
                    </div>
                  </div>
                  }
                  @if (!userAccount.registrationPending) {
                  <div fxFlex="50" fxFlex_xs="100" fxLayout="row" fxLayout_xs="column" class="last-login-container wrap-bg-grey">
                    <div class="title">Last Login :&nbsp;</div>
                    <div class="value text-break">
                      <span class="align mat-tooltip-panel"
                        matTooltip="Note: last login date means the last time the customer successfully input their email and password in the login screen to get to the next step, not necessarily when they successfully accessed the portal."
                        matTooltipPosition="above">
                        {{(userAccount.loginTryDate | date :'short') || '-'}}
                      </span>
                    </div>
                  </div>
                  }
                  <!-- <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center"
                  *ngIf="!userAccount.registrationPending">
                  <div fxFlex="30" class="p-2">Last Login IP:</div>
                  <div fxFlex="70" class="p-2 font-weight-bold">
                    <span>{{userAccount?.lastLoginIP || '-'}}</span>
                  </div>
                </div> -->
                  <!-- <div *ngIf="userList && userList.length > 1" fxFlex="100" fxLayout="row"
                fxLayout_xs="column" class="disclaimer text-danger">
                <mat-icon class="align mat-tooltip-panel" matTooltipPosition="below">warning</mat-icon>
                <span> Note - Mulitple emails&nbsp;</span>
                (
                <span
                  *ngFor="let info of userList;let i=index">{{(i < (userList.length-1) ? info.username + ',&nbsp;' : info.username) || '-'}}
                </span>
                )
                <span>&nbsp;mapped to this Customer ID.</span>
              </div> -->
                </mat-card-content>
                }
                @if (userAccount == null || notYetRegistered) {
                <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
                  <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" class="no-data text-break">
                    <span>Not Registered Yet</span>
                  </div>
                </mat-card-content>
                }
              </mat-expansion-panel>
            </mat-accordion>
          </mat-card-content>
        </mat-card>
        @if (showContent) {
        <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="3.5625rem" fxLayoutGap_xs="10px"
          fxLayout_xs="column" fxLayoutGap_lt-md="10px" fxLayout_lt-md="column">
          @if (customerInfo && linkCustomerEnabled) {
          <button mat-raised-button class="btn btn-sm ux-icon-button search-btn2" (click)="linkCustomer(customerInfo)"
            [disabled]="disableActionForMulitpleInstances" data-toggle="tooltip" title="Link Customer">Link
            Customer</button>
          }
          @if (userAccount && !notYetRegistered && !userAccount.suspended) {
          <button mat-raised-button class="btn btn-sm ux-icon-button search-btn2"
            [disabled]="!userAccount.enabled || disableActionForMulitpleInstances"
            (click)="showBlockAccount(userAccount)" data-toggle="tooltip" title="Block Online Account">Block Online
            Account</button>
          }
          @if (userAccount && !notYetRegistered && userAccount.suspended) {
          <button mat-raised-button class="btn btn-sm ux-icon-button search-btn2" (click)="showUnblockAccount(userAccount)"
            data-toggle="tooltip" title="Block Online Account" [disabled]="disableActionForMulitpleInstances">Unblock
            Online Account</button>
          }
          @if (userAccount && !notYetRegistered) {
          <button mat-raised-button class="btn btn-sm ux-icon-button search-btn2"
            [disabled]="(userAccount.pending || userAccount.status.value === 'Deactivate') || disableActionForMulitpleInstances"
            (click)="showChangeLogin(userAccount)" data-toggle="tooltip" title="Change Login Email">Change Login
            Email</button>
          }
          @if (userAccount && !notYetRegistered && !isCSRrole) {
          <button mat-raised-button class="btn btn-sm ux-icon-button search-btn2"
            [disabled]="userAccount.deactivated || disableActionForMulitpleInstances"
            (click)="showCloseOnlineAccount(userAccount)" data-toggle="tooltip" title="Close Online Account">Close
            Online Account</button>
          }
          @if (userAccount && userAccount.registrationPending) {
          <button mat-raised-button class="btn btn-sm ux-icon-button search-btn2" data-toggle="tooltip"
            [disabled]="disableActionForMulitpleInstances" title="Send Registration Email"
            (click)="sendRegistrationEmail(userAccount)">Send Registration
            Email</button>
          }
          @if ((userAccount==null || notYetRegistered) && customerInfo?.emails?.length>0) {
          <button mat-raised-button class="btn btn-sm ux-icon-button search-btn2" data-toggle="tooltip"
            [disabled]="disableActionForMulitpleInstances" title="Send Registration Email"
            (click)="sendInvitationEmail()">Send Invitation Email</button>
          }
          @if (!isShellAccount && customerInfo) {
          <button mat-raised-button class="btn btn-sm ux-icon-button search-btn2" [disabled]="disableActionForMulitpleInstances"
            (click)="openCustomerView()" data-toggle="tooltip" title="Customer View">Customer View</button>
          }
        </mat-card-actions>
        }
      <!-- </mat-card-content>
    </mat-card> -->
    }
  </mat-card-content>
</mat-card>
}