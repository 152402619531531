import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment-timezone';
import * as _ from 'underscore';
import { DataTableConfig, DataTableRowConfig } from '@londonhydro/ux-lib';
import { Audit, AuditEvents, UIUtil  } from '@londonhydro/utility-model';
import { ErrorLabelPipe } from '../../framework/shared/service/error-label.pipe';
import { AdditionalDataViewerComponent } from './additional-data-viewer/additional-data-viewer.component';
import { IamDataService } from '@londonhydro/iam-api';

@Component({
  selector: 'app-audit-form',
  templateUrl: './audit-form.component.html',
  styleUrls: ['./audit-form.component.scss']
})
export class AuditFormComponent implements OnInit, AfterViewInit {

  auditListDS: MatTableDataSource<any> = null;
  formsFilterForm: UntypedFormGroup;
  auditEventList: any[] = AuditEvents.AllEventList;
  statusList: any[] = ['Failure', 'Success', 'All'];
  advSearchOn = false;
  @ViewChild('auditTableSort') sort: MatSort;
  @ViewChild('formsPaginator') auditPaginator: MatPaginator;
  displayedColumns: string[];
  localtz = moment.tz.guess();
  defaultDateDisclaimer = true;
  resetForm: boolean;
  tokenRegex: RegExp = new RegExp(/^[a-z0-9-]+$/);
  formAttributeConfig: any[] = [
    { name: 'rowIndex', label: '#', type: 'string', class: 'sl-index', sort: true },
    { name: 'userEmail', label: 'Username', type: 'string', class: 'auditUserName', sort: true },
    { name: 'name', label: 'Event Name', type: 'string', class: 'auditEventName', sort: true },
    { name: 'description', label: 'Description', type: 'string', class: 'auditDescription', sort: true, translate: true },
    { name: 'status', label: 'Status', type: 'string', class: 'auditStatus', sort: false },
    { name: 'createdDate', label: 'Created On', type: 'date', class: 'auditCreatedDate', sort: true,
     dateFormat: 'short', timezone: UIUtil.UiTimeZone},
    { name: 'additionalData', label: 'Additional Data', type: 'additionalDataViewer', class: 'auditAdditionalData', sort: false},
  ];

  dataTableConfiguration: DataTableConfig;

  constructor(private formBuilder: UntypedFormBuilder, private matDialog: MatDialog, private datePipe: DatePipe,
              private translatePipe: ErrorLabelPipe, private iamDataService: IamDataService) {
    this.resetSearchForm();
  }

  ngOnInit(): void {
    this.generateDataTableConfig();
    this.getAuditEvents();
  }

  generateDataTableConfig(): void{
    this.dataTableConfiguration = new DataTableConfig(this.formAttributeConfig);
    this.dataTableConfiguration.tableFooterDisclaimer = '** Please note: Data is displayed for last 30 days.';
    this.dataTableConfiguration.advancedSearchEnabled = true;
    this.dataTableConfiguration.quickSearchEnabled = true;
    this.dataTableConfiguration.refreshEnabled = true;
    this.dataTableConfiguration.exportDataEnabled = true;
  }

  ngAfterViewInit(): void {
  }

  private resetSearchForm(): void {
    this.resetForm = true;
    this.formsFilterForm = this.formBuilder.group({
      userName: [''],
      eventName: [''],
      status: [this.statusList[0]],
      startDate: [],
      endDate: [],
    }, { validator: this.dateValidator });
  }

  dateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const startDt = control.get('startDate');
    const endDt = control.get('endDate');
    if (startDt?.value !== null && endDt?.value !== null){
      return startDt?.value > endDt?.value ? { dateInvalid: true } : null;
    } else {
      return null;
    }
  }

  onResetFormSubmit(): void {
    this.resetSearchForm();
    this.getAuditEvents();
  }

  dateChangeHandler(dateFormControl: string): void {
    console.log('viewPeriodChangeHandler:::', this.formsFilterForm);
  }


  getAuditEvents(): void {
    const userName = this.formsFilterForm.controls.userName.value;
    let eventName = this.formsFilterForm.controls.eventName.value;
    const formValue = this.formsFilterForm.value;

    if (formValue.eventName === 'All') {
      eventName = '';
    }
    if (formValue.startDate) {
      formValue.startDate = moment.tz(formValue.startDate, this.localtz).format('YYYY-MM-DD');
    }
    if (formValue.endDate) {
      formValue.endDate = moment.tz(formValue.endDate, this.localtz).format('YYYY-MM-DD');
    }
    const statusList = formValue.status;

    this.iamDataService.getAuditList(userName, eventName, 'Failure', formValue.startDate, formValue.endDate);
    this.iamDataService.auditList
      .subscribe(
        (auditList: Audit[]) => {
          _.each(auditList, (audit: Audit, index: number) => {
            if (audit && audit.createdDate){
            audit.createdDate = moment.utc(audit.createdDate).toDate();
          }
            audit.rowIndex = (index + 1);
            if (audit.additionalData && audit.additionalData != null && audit.additionalData.length > 0) {
              try {
                audit.additionalData = JSON.parse(audit.additionalData);
              } catch (e) {
                console.log('catch additionalData', e);
                audit.additionalData = audit.additionalData;
                if (this.tokenRegex.test(audit.additionalData)){
                  audit.additionalData = 'Expired token - ' + audit.additionalData;
                }
              }
            }
          });
          const auditListLen = auditList.length;
          const clonedAuditList = JSON.parse(JSON.stringify(auditList));
          for (let i = 0; i < auditListLen; i++) {
            this.formAttributeConfig.forEach((item) => {
              if (item.translate === true) {
                if (clonedAuditList[i][item.name]) {
                  clonedAuditList[i][item.name] = this.translatePipe.transform(clonedAuditList[i][item.name]);
                }
              }
            });
          }
          this.auditListDS = clonedAuditList;
          this.defaultDateDisclaimer = !this.resetForm ? false : true;
          this.resetForm = false;
        },
        (error: any) => {
          console.log('Error Audit Queue Search Completed:', error);
        }
      );
  }

  toggleAdvSearch(): void {
    this.advSearchOn = !this.advSearchOn;
  }


  viewAdditionalData(data: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'additional-data-modal-component' + data.id;
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.data = data.additionalData;
    const additionalDataViewer = this.matDialog.open(AdditionalDataViewerComponent, dialogConfig);
  }

  getActions(emittedValue: any): void {
    const obj = JSON.parse(emittedValue);
    if (obj.action === 'additionalData') {
      this.viewAdditionalData(obj.value);
    } else if (obj.action === 'refresh') {
      this.onResetFormSubmit();
    } else if (obj.action === 'export') {
      this.exportToCSV(obj.value);
    } else if (obj.action === 'toggle-search'){
      this.toggleAdvSearch();
    }
  }

  exportToCSV(filteredData:any): void {
    const tableDataCSV = [];
    filteredData.forEach((element) => {
      const configHeader = this.formAttributeConfig;
      const tempObj: any = {};
      for (const attribute of configHeader) {
        let val = element[attribute.name];
        if (attribute.type === 'date') {
          val = this.datePipe.transform(val, 'mediumDate');
        } else if (attribute.name === 'description') {
          val = this.translatePipe.transform(val);
        } else if (attribute.type === 'additionalData') {
          if (element.additionalData) {
            if (element.additionalData.actual && element.additionalData.actual.challenge) {
              val = 'User Input Details:'
              if (element.additionalData?.actual?.challenge) {
                const actualList = Object.entries(element.additionalData?.actual?.challenge);
                for (const item of actualList) {
                  val += ' ' + item[0] + '-' + (item[1] != null ? item[1] : 'NULL') + ', ';
                }
              }
            }
            if (element.additionalData.expected) {
              const expectedList = Object.entries(element.additionalData.expected);
              val = val + '; Expected Details:';
              for (const item of expectedList) {
                const field = item[0];
                const value = item[1] as any;
                val += ' ' + field + '-' + (value != null && value.value1 != null ? value.value1 : 'NULL') + ', '
              }
            }
          }
        }
        const key = attribute.label;
        tempObj[key] = val ? val : '';
      }
      tableDataCSV.push(tempObj);

    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'Error_Queue_' + this.datePipe.transform(new Date(), 'shortDate').split('/').join('.')

    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(tableDataCSV);
  }
}