<!-- @if (formListDS) { -->
  <div fxLayout="column">
    <!-- <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="my-2 mtabset" fxFlex="100" fxLayout="row"
    fxLayoutAlign="space-between center" fxLayoutGap="15px">
    <a mat-tab-link (click)="changeTabView(tab)" *ngFor="let tab of tablinks" fxFlex="50" [active]="activeTab == tab"
      class="content-tab-link mx-1">
    {{tab | uppercase}} </a>
  </nav> -->
  <!-- <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel> -->
  <mat-sidenav-container class="default-sidenav p-2" style="min-height: 900px;" [ngStyle]="{'min-height': screensize_ltsm ? '1000px' : null}">
    <mat-sidenav #sidenav mode="side"  [opened]="!showFullMatTable"
      class="ux-side-nav">
      <div [ngClass]="{'hide': (showFullMatTable || !screensize_ltmd)}" >
        <button (click)="toggleMatTable()" class="close p-1 float-right" type="button" id="dismiss" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- <div *ngIf="statusLinks != null">
      <label id="filter-group-label" class="font-weight-bold color-secondary">Queue</label>
      <mat-chip-list aria-label="Quick selection" [selectable]="true" class="mat-chip-list-stacked">
        <mat-chip *ngFor="let statuslink of statusLinks; let i = index" (click)="searchForm(statuslink)" class="ux-chip pl-2"
          [selected]="statuslink.name === selectedStatus.name" [attr.data-index]="i" color="primary">
          {{statuslink.displayName | translate}} ({{statuslink.count}})
        </mat-chip>
      </mat-chip-list>
    </div>
    <mat-divider class="my-3"></mat-divider> -->
    @if (qLinks != null) {
      <div>
        <label id="filter-group-label" class="ux-group-label pt-2 pl-1">View Requests By </label>
        <mat-radio-group aria-labelledby="filter-group-label" class="filter-radio-group"
          [(ngModel)]="selectedFilterType" (change)="populateRequestFilter()">
          @for (filterGroup of filterGroups; track filterGroup) {
            <mat-radio-button class="filter-radio-button" [value]="filterGroup">
              <span class="ux-group-value">{{filterGroup | uppercase}}</span>
            </mat-radio-button>
          }
        </mat-radio-group>
        <mat-chip-list aria-label="Quick selection" [selectable]="true" class="mat-chip-list-stacked">
          @for (qlink of qLinks; track qlink; let i = $index) {
            <mat-chip [disabled]="qlink.count===0?true:false" (click)="applyFormsFilter(qlink)" class="ux-chip pl-2"
              [selected]="qlink.name === selectedQlink.name" [attr.data-index]="i" color="primary">
              @if (selectedFilterType !== 'Status') {
                <span>{{(qlink.displayName || 'undefined')}} ({{qlink.count}})</span>
              }
              @if (selectedFilterType === 'Status' && i > 0) {
                <span>{{qlink.displayName | labelTranslate: 'cis'}} ({{qlink.count}})</span>
              }
              @if (selectedFilterType === 'Status' && i == 0) {
                <span>{{qlink.displayName | labelTranslate}} ({{qlink.count}})</span>
              }
            </mat-chip>
          }
        </mat-chip-list>
      </div>
    }
  </mat-sidenav>
  <mat-sidenav-content>
    <!--Search Box-->
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-around stretch" class="pl-3">
      @if (formsFilterForm && advSearchOn) {
        <form [formGroup]="formsFilterForm" (ngSubmit)="getCustomerServiceRequests()"
          >
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            @if (selectedFilterType != 'Name') {
              <div fxLayout="column" fxFlex="48" fxFlex_lt-sm="80">
                <mat-form-field appearance="outline" data-toggle="tooltip" title="Search By Process">
                  <mat-label>Process:</mat-label>
                  <input matInput type="text" placeholder="" formControlName="formName">
                </mat-form-field>
              </div>
            }
            <!-- <div fxLayout="column" fxFlex="48" *ngIf="selectedFilterType != 'Category'">
            <mat-form-field appearance="outline" data-toggle="tooltip" title="Search By Request Category">
              <mat-label>Request Category:</mat-label>
              <input matInput type="text" placeholder="" formControlName="category">
            </mat-form-field>
          </div> -->
          <!-- <div fxLayout="column" fxFlex="48" fxFlex_lt-sm="80">
          <mat-form-field appearance="outline" data-toggle="tooltip" title="Search By Email ID">
            <mat-label>Email ID:</mat-label>
            <input matInput type="text" placeholder="" formControlName="emailAddress">
          </mat-form-field>
        </div> -->
        <div fxLayout="column" fxFlex="48" fxFlex_lt-sm="80">Requested Date:
          <div fxLayout="row">
            <div fxLayout="column" fxFlex="48" class="mb-4">
              <mat-form-field appearance="fill" data-toggle="tooltip" title="Search From Requested Date">
                <mat-label>From:</mat-label>
                <input matInput [matDatepicker]="pickerFromDate" formControlName="requestedOnAfter"
                  (dateChange)="dateChangeHandler('requestedOnAfter')">
                  <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                  <mat-datepicker-toggle matSuffix [for]="pickerFromDate"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFromDate></mat-datepicker>
                </mat-form-field>
                <!-- <span>
                <mat-error *ngIf="formsFilterForm.controls.requestedOnBefore.value && formsFilterForm.controls.requestedOnAfter.value && formsFilterForm.controls.requestedOnBefore.value < formsFilterForm.controls.requestedOnAfter.value">
                  From date cannot be greater than To date.
                </mat-error>
              </span>
              -->
              <!-- <mat-form-field appearance="outline">
              <input matInput type="text" placeholder="" formControlName="requestedOnAfter">
            </mat-form-field> -->
          </div>
          <div  fxLayout="column" fxFlex="48" style="margin-left:1.5rem;" >
            <mat-form-field appearance="fill" data-toggle="tooltip" title="Search upto Requested Date">
              <mat-label>To:</mat-label>
              <input matInput [matDatepicker]="pickerToDate" formControlName="requestedOnBefore"
                (dateChange)="dateChangeHandler('requestedOnBefore')">
                <mat-datepicker-toggle matSuffix [for]="pickerToDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerToDate></mat-datepicker>
              </mat-form-field>
              <!-- <span>
              <mat-error *ngIf="formsFilterForm.controls.requestedOnBefore.value && formsFilterForm.controls.requestedOnAfter.value && formsFilterForm.controls.requestedOnBefore.value < formsFilterForm.controls.requestedOnAfter.value">
                From date cannot be greater than To date.
              </mat-error>
            </span> -->
          </div>
        </div>
        <div fxLayout="column" fxFlex="96" fxFlex_lt-sm="80">
          <span>
            @if (formsFilterForm.controls.requestedOnBefore.value && formsFilterForm.controls.requestedOnAfter.value && formsFilterForm.controls.requestedOnBefore.value < formsFilterForm.controls.requestedOnAfter.value) {
              <mat-error>
                Please provide a valid date. From date cannot be greater than To date.
              </mat-error>
            }
          </span>
        </div>
      </div>
      @if (selectedFilterType !== 'Status') {
        <div fxLayout="column" fxFlex="48" fxFlex_lt-sm="80">
          <mat-form-field appearance="outline" data-toggle="tooltip" title="Search By Status">
            <mat-label>Status:</mat-label>
            <mat-select #statusSelect formControlName="status" multiple >
              <mat-option #allSelected [value]="statusList[0].value" (click)="toggleStatusAll(statusSelect)">{{statusList[0].resourceLabel | labelTranslate}}
              </mat-option>
              @for (sStatus of statusList | slice:1; track sStatus; let i = $index) {
                <mat-option (click)="toggleStatusSelection()" [value]="sStatus.value">{{sStatus.resourceLabel | labelTranslate: 'cis'}}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
      <div fxLayout="column" fxFlex="48" fxFlex_lt-sm="80">
        <mat-form-field appearance="outline" data-toggle="tooltip" title="Search with Request No">
          <mat-label>Request No:</mat-label>
          <input matInput type="text" placeholder="" formControlName="requestNo">
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" class="mb-4 mt-3">
      <button  class="btn btn-sm ux-icon-button" type="submit">
        Search
      </button>
      <button  class="btn btn-sm ux-icon-button" type="reset" (click)="changeTabView(activeTab,true)" >
        Reset
      </button>
    </div>
  </form>
}
<div fxLayout="column" fxLayoutAlign="space-between stretch" class="mt-2">
  @if (formsFilterForm) {
    <div fxLayout="row" fxLayout_lt-sm="column" fxLayout_lt-md="column" fxFlex="100" fxLayoutAlign="end center"
      fxLayoutGap="15px" class="mb-2">
      <!--@if (!advSearchOn) {
        <mat-form-field id="quickSearch" class="mt-3">
          <mat-label>Quick search</mat-label>
          <input matInput class="form-field" #quickSearchInput [formControl]="quickSearch" (keyup)="applyFilter($event.target.value)">
          <button matSuffix mat-icon-button aria-label="Clear"
            (click)="applyFilter(''); quickSearchInput.value=''">
            <mat-icon matSuffix>close</mat-icon>
          </button>
        </mat-form-field>
      }
      <button type="button" class="btn btn-sm ux-icon-button" title="Refresh"
        (click)="changeTabView(activeTab)" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon data-toggle="tooltip" data-placement="bottom" title="Refresh">refresh
        </mat-icon>
      </button>-->
      <button type="button" class="btn btn-sm ux-icon-button" title="Hide Filter" sty
        (click)="toggleMatTable()" fxLayout="row" fxLayoutAlign="center center"
        [ngClass]="{'d-none' : screensize_ltmd && !showFullMatTable}" >
        <mat-icon data-toggle="tooltip" data-placement="bottom" title="Hide Filter">
        filter_list</mat-icon>
      </button>
      <!-- <button *ngIf="showFullMatTable" type="button" class="btn btn-sm ux-icon-button" title="Toggle Mat Table" sty
      (click)="toggleMatTable()" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon data-toggle="tooltip" data-placement="bottom" title="Toggle Mat Table" class="mr-2">
      filter_list</mat-icon>
      <span>{{showSideNav ?'Hide' : 'Show'}}  Filter</span>
    </button> -->
    <button type="button" class="btn btn-sm ux-icon-button" title="Advanced Search Form" sty
      (click)="toggleAdvSearch()" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon data-toggle="tooltip" data-placement="bottom" title="Advanced Search Form">
      manage_search</mat-icon>
    </button>
    <!-- <button type="button" class="btn btn-sm ux-icon-button" title="Export Data in CSV format"
      (click)="exportToCSV()" fxLayout="row" fxLayoutAlign="center center"> --> <!-- exporter.exportTable('csv') -->
      <!--<mat-icon data-toggle="tooltip" data-placement="bottom" title="Export Data">file_download
      </mat-icon>
    </button>
    <button class="btn btn-sm ux-icon-button" (click)="expandAllRows(expandAllSubRow)"
  fxLayout="row" fxLayoutAlign="start center" data-toggle="tooltip" title="Display additional details">{{expandAllSubRow?'Hide':'Show'}} Details</button>-->
  <div class="archive-box"  fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="center center" data-toggle="tooltip" title="Display archived forms">
    <span>Pending</span>
    <mat-slide-toggle [(ngModel)]="currentToArchive" (change)="currentToArchiveFilter()"></mat-slide-toggle>
    <span>Processed</span>
  </div>
</div>
}
<div>
  <!-- <div fxLayout="row" fxLayoutAlign="end center" *ngIf="defaultSearch">
  <span class="text-secondary">*You are viewing the forms that were submitted in the last 7 days</span>
</div> -->
<uxlib-data-table [dataTableConfig]="dataTableConfiguration" [data]="formListDS"
    (newActionEvent)="getActions($event)" [advSearchOn]="advSearchOn"></uxlib-data-table>
<!-- <mat-table matTableExporter multiTemplateDataRows [dataSource]="formListDS" class="live-data-container" matSort
  #exporter="matTableExporter" 
    #csrTableSort="matSort">
  <ng-container matColumnDef="index">
    <mat-header-cell 
      *matHeaderCellDef class="form-checkbox"><span>
    </span>
  </mat-header-cell>
  <mat-cell *matCellDef="let element; let i = index" class="form-checkbox" 
    fxFlex_lt-sm="100">
    <span fxFlex_lt-sm="65">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
        [checked]="selection.isSelected(element)">
      </mat-checkbox>
    </span> </mat-cell>
  </ng-container>
  @for (attribute of (activeTab==='My Queue' ? formAttributeConfigMQ : formAttributeConfig); track attribute; let i = $index) {
    <ng-container
      matColumnDef="{{attribute.name}}" >
      <mat-header-cell *matHeaderCellDef  mat-sort-header [disabled]="attribute.name==='action'"
							[class]="attribute.type + ' ' + (attribute.class || '')+' ' + (attribute.visibility==='hidden' && showFullMatTable===false?'hide':'')
							+' '+(attribute.subRow===true?'hide':'')">	
        <div>{{attribute.label}}</div>
      </mat-header-cell>
      <mat-cell class="matCell "  *matCellDef="let element;" 
									[class]="attribute.type + ' ' + (attribute.class || '') + ' ' + (attribute.dataClass? element[attribute.dataValue] : '')
									+' ' + (attribute.visibility==='hidden' && showFullMatTable===false?'hide':'')+' '+(attribute.subRow===true?'hide':'')">
        @if (attribute.type === 'string' && attribute.subRow!=true) {
          <span fxFlex_lt-sm="100">
            @if (attribute.name != 'assignedUser.fullName') {
              <span fxFlex_lt-sm="100"><span class="d-none"
               fxFlex_lt-sm="35">{{attribute.label}}:</span>
              @if (!attribute.translate) {
                <span fxLayoutAlign_lt-sm="start" fxFlex_lt-sm="65">{{element.getDeepData(attribute.name)}} </span>
              }
              @if (attribute.translate) {
                <span fxLayoutAlign_lt-sm="start" fxFlex_lt-sm="65">{{element.getDeepData(attribute.name) | labelTranslate: 'cis'}} </span>
              }
            </span>
          }
          @if (attribute.name === 'assignedUser.fullName') {
            <span fxFlex_lt-sm="100">
              <span class="d-none" fxFlex_lt-sm="35">{{attribute.label}}:</span>
              <span>
                {{activeTab === 'My Queue'?'':(element.getDeepData(attribute.name) || element.getDeepData(attribute.secondaryData))}}
              </span>
              @if (element.getDeepData('assignedUser')===null && !this.superAccountUser) {
                <button type="button" class="btn btn-link p-0 font-italic"
                  (click)="assignToMe(element.requestId)"><span
                  class="break-space">Assign to
                me</span></button>
              }
              @if (element.getDeepData('assignedUser')===null && this.superAccountUser) {
                <button type="button" class="btn btn-link p-0 font-italic" fxLayoutAlign_lt-sm="start"
                  (click)="assignToOthersWorkflow(element,true)"><span
                class="break-space" fxLayoutAlign_lt-sm="start">Assign</span></button>
              }
              @if (activeTab==='My Queue') {
                <button type="button" class="btn btn-link p-0 font-italic" fxLayoutAlign_lt-sm="start"
                  (click)="unassign(element.requestId)"><span class="break-space"
                  fxLayoutAlign_lt-sm="start">Remove from
                myqueue</span></button>
              }
            </span>
          }
        </span>
      }
      @if (attribute.type === 'rowIndex') {
        <span  fxFlex_lt-sm="100"><span class="d-none"
         fxFlex_lt-sm="35">{{attribute.label}}:</span><span
      fxFlex_lt-sm="65">{{element.getDeepData(attribute.name)}}</span></span>
    }
    @if (attribute.type === 'number') {
      <span  fxFlex_lt-sm="100"><span class="d-none"
       fxFlex_lt-sm="35">{{attribute.label}}:</span><span
    fxFlex_lt-sm="65">{{element.getDeepData(attribute.name) | number: '1.0-2'}}</span></span>
  }
  @if (attribute.type === 'date') {
    <span  fxFlex_lt-sm="100"><span class="d-none"
     fxFlex_lt-sm="35">{{attribute.label}}:</span><span
  fxFlex_lt-sm="65">{{attribute.dateFormat ? (element.getDeepData(attribute.name) | date: attribute.dateFormat : uiTimezone ) : element.getDeepData(attribute.name)}}</span></span>
}
@if (attribute.type === 'detailview') {
  <span fxFlex_lt-sm="100">
    <span class="d-none" fxFlex_lt-sm="35">{{attribute.label}}:</span>
    <span fxFlex_lt-sm="65" fxLayoutAlign_lt-sm="start">{{element.getDeepData(attribute.name)}}</span>
  </span>
}
@if (attribute.type === 'action') {
  <div fxFlex_lt-sm="100">
    <span class="d-none" fxFlex_lt-sm="35">{{attribute.label}}:</span>
    <button fxFlex_lt-sm="65" fxLayoutAlign_lt-sm="start" type="button" class="btn btn-link p-0" data-toggle="tooltip" title="View Details" (click)="viewRequest(element)"><span>View</span></button>
  </div>
}
</mat-cell>
</ng-container>
}
<ng-container matColumnDef="expandedDetail" >
  <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
    <div class="row mx-auto" [@detailExpand]="element.rowExpanded ? 'expanded' : 'collapsed'">
      @for (attribute of (activeTab==='My Queue' ? formAttributeConfigMQ : formAttributeConfig); track attribute; let i = $index) {
        <div >
          @if (element.getDeepData(attribute.name)) {
            <span>
              @if (attribute.subRow===true) {
                <span class="col"><span>{{attribute.label}} : </span>-->
                <!-- <span class="font-weight-bold" *ngIf="attribute.type !== 'date'">{{element.getDeepData(attribute.name)}}</span> -->
                <!--@if (attribute.type === 'date') {
                  <span class="font-weight-bold">{{attribute.dateFormat ? (element.getDeepData(attribute.name) | date: attribute.dateFormat : uiTimezone ) : element.getDeepData(attribute.name)}}</span>
                }
              </span>
            }
          </span>
        }
      </div>
    }
  </div>
</mat-cell>
</ng-container>
<tr class="mat-row d-flex" *matNoDataRow>
  <td class="mat-cell d-flex w-100 p-3 font-weight-bold" fxLayoutAlign="center"
    [attr.colspan]="displayedColumns.length">
    No data matching the filter.
  </td>
</tr>
<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"
class="matHeaderRow"></mat-header-row>
<mat-row *matRowDef="let row; columns: displayedColumns;" fxLayout="row" fxLayout_xs="column" class="matHeaderRow"
  >
</mat-row>
<mat-row class="expanded-row" *matRowDef="let row; columns: ['expandedDetail']"
[class]="row.rowExpanded===true?'d-block':'d-none'"></mat-row>
</mat-table> -->
<!-- <div fxLayout="row" fxLayout_lt-sm="column-reverse" class="table-footer">
  @if (showDisclaimerText && !advSearchOn) {
    <div fxFlex="40" fxFlex_lt-sm="100" fxLayoutAlign="start center" class="table-disclaimers">
      <span class="disclaimer">
        <em>** Please Note: Data is displayed for the last 7 days. Please use the Advanced Search option for further filtration.</em>
      </span>
    </div>
  }
  <div fxFlex="100" fxFlex_lt-sm="100" fxLayoutAlign="end stretch">
    @if (formsFilterForm) {
      <mat-paginator #formsPaginator="matPaginator" [pageSizeOptions]="[10, 25, 50]"
        showFirstLastButtons [pageSize]="10" aria-label="Select page">
      </mat-paginator>
    }
  </div>
</div> -->
</div>
</div>
</div>
</mat-sidenav-content>
</mat-sidenav-container>
</div>
<!-- } -->