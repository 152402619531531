@if (!isUserViewClicked) {
  <mat-card fxLayout="column">
    <mat-card-content fxLayout="column">
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div [formGroup]="searchUserForm" fxLayout="row wrap" fxFlex="55" fxLayoutAlign="center">
          <mat-label fxFlex_lt-sm="100" fxFlex="25" class="font-weight-bold mt-3">
            <h3>Search Keyword</h3>
          </mat-label>
          <mat-form-field fxFlex_lt-sm="100" fxFlex="75" fxLayout_lt-sm="row" appearance="outline">
            <input matInput type="text" id="searchKey" class="pb-1" placeholder="Search by Username, Name or CustomerId"
              formControlName="searchKey">
            <button matSuffix mat-icon-button aria-label="Clear"
              (click)="searchUserForm.controls.searchKey.setValue(null)">
              <mat-icon matSuffix>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        @if (advSearch) {
          <div fxLayout="column" fxLayout_xs="column" fxFlex="55" fxLayoutAlign="center" fxLayoutGap="20px"
            >
            <div fxFlex="75" fxLayout="row wrap" fxLayoutAlign="center">
              <mat-label fxFlex_lt-sm="100" fxFlex="25" class="font-weight-bold mt-3">
                <h3>Roles</h3>
              </mat-label>
              <mat-form-field fxFlex_lt-sm="100" fxFlex="75">
                <mat-select [formControl]="searchByRolesFormControl" multiple>
                  <mat-select-trigger>
                    <mat-chip-list>
                      @for (roleChipValue of searchByRolesFormControl.value; track roleChipValue) {
                        <mat-chip [removable]="true"
                          (removed)="onRolesRemoved(roleChipValue)">
                          {{ roleChipValue.label }}
                          <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      }
                    </mat-chip-list>
                  </mat-select-trigger>
                  @for (role of roles; track role) {
                    <mat-option [value]="role">{{role.label}}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="75" fxLayout="row wrap" fxLayoutAlign="center">
              <mat-label fxFlex_lt-sm="100" fxFlex="25" class="font-weight-bold mt-3">
                <h3>Status</h3>
              </mat-label>
              <mat-form-field fxFlex_lt-sm="100" fxFlex="75">
                <mat-select [formControl]="searchByStatusFormControl" multiple>
                  <mat-select-trigger>
                    <mat-chip-list>
                      @for (statusChipValue of searchByStatusFormControl.value; track statusChipValue) {
                        <mat-chip [removable]="true"
                          (removed)="onStatusRemoved(statusChipValue)">
                          {{ statusChipValue.label }}
                          <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      }
                    </mat-chip-list>
                  </mat-select-trigger>
                  @for (st of status; track st) {
                    <mat-option [value]="st"> {{st.label}}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        }
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="mt-3">
        <button data-toggle="tooltip" title="search user" class="btn btn-sm ux-icon-button " (click)="searchList()">
          Search
        </button>
      </div>
      <!--    <div *ngIf="showTable == false && dataSource == null" class="text-danger text-center">
      <h2>No Result Found. !!</h2>
    </div> -->
    <div fxLayout="column" fxLayoutAlign="space-between stretch" class="mt-4">
      <div fxLayout="row" fxLayout_lt-sm="column" fxLayout_lt-md="column" [ngClass]="{'mb-4' : screensize_ltmd}"
        [ngClass]="{'mb-4' : screensize_ltsm}" fxFlex="100" fxLayoutAlign="end center" fxLayoutGap="15px">
        @if (dataSource) {
          <mat-form-field id="quickSearch" class="mt-3">
            <mat-label>Quick search</mat-label>
            <input matInput class="form-field" #quickSearchInput (keyup)="applyFilter($event.target.value)">
            <button matSuffix mat-icon-button aria-label="Clear"
              (click)="applyFilter(''); quickSearchInput.value=''">
              <mat-icon matSuffix>close</mat-icon>
            </button>
          </mat-form-field>
        }
        <button type="button" class="btn btn-sm ux-icon-button" fxLayout="row" fxLayoutAlign="start center" (click)="advancedSearch()">
          <span>{{advSearch ? "Hide" : "Show"}} Advanced Search</span>
        </button>
        <button type="button" class="btn btn-sm ux-icon-button" [ngClass]="{'selected-button' : searchType === 'SHELL'}" fxLayout="row" fxLayoutAlign="start center" (click)="searchList('SHELL')">
          <span>Administer Shell Accounts</span>
        </button>
        <button type="button" class="btn btn-sm ux-icon-button" [ngClass]="{'selected-button' : searchType === 'REGISTERED'}" fxLayout="row" fxLayoutAlign="start center" (click)="searchList('REGISTERED')">
          <span>Administer Registered Accounts</span>
        </button>
        @if (dataSource.data.length>0) {
          <button type="button" class="btn btn-sm ux-icon-button" title="Export Data in CSV format"
            (click)="exportToCSV()" fxLayout="row" fxLayoutAlign="start center">
            <!-- exporter.exportTable('csv') -->
            <mat-icon data-toggle="tooltip" data-placement="bottom" title="Export Data" class="mr-2">file_download
              </mat-icon><span>Export Data</span>
            </button>
          }
        </div>
      </div>
      <mat-table [dataSource]="dataSource" class="live-data-container mat-elevation-z8 mt-2"
        [ngClass]="{'mat-table-responsive' : screensize_ltmd}" [hidden]="!dataSource"
        #userSearchTableSort="matSort" matSort>
        <ng-container matColumnDef="index">
          <mat-header-cell *matHeaderCellDef class="sl" mat-sort-header><span>#</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="sl"
          [ngClass]="{'mat-cell-responsive-sm padding-left-md' : screensize_ltsm}"> <span class="d-none"
        [ngClass]="{'mobile-label-display' : screensize_ltsm}">Sl:</span><span [ngClass]="{'ml-2' : screensize_ltsm}">
      {{element.rowIndex}}</span> </mat-cell>
    </ng-container>
    @for (attribute of formAttributeConfig; track attribute; let i = $index) {
      <ng-container matColumnDef="{{attribute.name}}">
        <mat-header-cell *matHeaderCellDef class="pl-1" [class]="attribute.class" mat-sort-header
          [disabled]="attribute.type==='action'">
          <div>
            {{attribute.label}}
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="matCell pl-1" [class]="attribute.class"
          [ngClass]="{'mat-cell-responsive-sm padding-left-md' : screensize_ltsm}">
          @if (attribute.type === 'role') {
            <span fxFlex_lt-sm="100">
              <span [ngClass]="{'d-flex' : screensize_ltsm}" fxFlex_lt-sm="100">
                <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}" fxFlex_lt-sm="35">Role</span>
                <span class="font-weight-bold" fxFlex_lt-sm="65">{{element[attribute.name] || '-' }}
                </span>
              </span>
            </span>
          }
          @if (attribute.type === 'phone') {
            <span fxFlex_lt-sm="100">
              <span [ngClass]="{'d-flex' : screensize_ltsm}" fxFlex_lt-sm="100">
                <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}" fxFlex_lt-sm="35">Phone</span>
                <span [ngClass]="{'ml-2' : screensize_ltsm}"
                  fxFlex_lt-sm="65">{{!element[attribute.name] ? '-' : element[attribute.name] | phonePipe}}
                </span>
              </span>
            </span>
          }
          @if (attribute.type === 'string') {
            <span fxFlex_lt-sm="100">
              <span [ngClass]="{'d-flex' : screensize_ltsm}" fxFlex_lt-sm="100">
                <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}" fxFlex_lt-sm="35">{{attribute.label}}</span>
                <span [ngClass]="{'ml-2' : screensize_ltsm}" fxFlex_lt-sm="65">{{element[attribute.name] || '-'}}
                </span></span>
              </span>
            }
            @if (attribute.type === 'status') {
              <span fxFlex_lt-sm="100">
                <span [ngClass]="{'d-flex' : screensize_ltsm}" fxFlex_lt-sm="100">
                  <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}" fxFlex_lt-sm="35">{{attribute.label}}</span>
                  <span [ngClass]="{'ml-2' : screensize_ltsm}" [class]="element.status.value"
                    fxFlex_lt-sm="65">{{( 'user.status.' + element['userStatus'] |  labelTranslate )|| '-'}}
                  </span></span></span>
                }
                @if (attribute.type === 'date') {
                  <span fxFlex_lt-sm="100">
                    <span [ngClass]="{'d-flex' : screensize_ltsm}" fxFlex_lt-sm="100">
                      <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}" fxFlex_lt-sm="35">{{attribute.label}}</span>
                      <span fxFlex_lt-sm="65"
                        [ngClass]="{'ml-2' : screensize_ltsm}">{{!element[attribute.name] ? '-' : element[attribute.name] | date: 'short' : uiTimezone }}
                      </span></span></span>
                    }
                    @if (attribute.type === 'action') {
                      <span fxFlex_lt-sm="100">
                        <span [ngClass]="{'d-flex' : screensize_ltsm}" fxFlex_lt-sm="100">
                          <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}" fxFlex_lt-sm="35">{{attribute.label}}</span>
                          <span [ngClass]="{'ml-2' : screensize_ltsm}" fxFlex_lt-sm="65"><button data-toggle="tooltip" title="View User Details"
                          class="btn btn-link p-0" (click)="userDetailViewEnabled(element)">User View</button>
                        </span></span></span>
                      }
                    </mat-cell>
                  </ng-container>
                }
                <tr class="mat-row d-flex" *matNoDataRow>
                  <td class="mat-cell d-flex w-100 p-3 font-weight-bold" fxLayoutAlign="center"
                    [attr.colspan]="displayedColumns.length">
                    No data available.
                  </td>
                </tr>
                <mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'d-none' : screensize_ltsm}"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'responsive-mat-row' : screensize_ltmd}"
                [ngClass]="{'responsive-mat-row-small' : screensize_ltsm}"></mat-row>
              </mat-table>
              <!-- <mat-paginator #paginator="matPaginator"  *ngIf="dataSource" [length]="dataSource.data?.length"
              [pageSize]="dataSource.filteredData.length > 10 ? '10' : dataSource.filteredData.length"
              [pageSizeOptions]="[10,20,30,40,50]" showFirstLastButtons aria-label="Show users"
              [ngClass]="dataSource !== null && dataSource.filteredData.length > 0  ? 'visible' : 'hidden'">
            </mat-paginator>  -->
            @if (dataSource) {
              <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[10, 20, 30, 40, 50]" showFirstLastButtons
                [pageSize]="10" aria-label="Select page">
              </mat-paginator>
            }
          </mat-card-content>
        </mat-card>
      }
