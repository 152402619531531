<div id="modal-content-wrapper">
  <section id="modal-body">

    <mat-card appearance="outlined" class="ux-card border border-light p-0">
      <mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title>Block Online Account</mat-card-title>
        <button (click)="closeModal()" class="btn btn-link" type="button" id="dismiss" aria-label="Close">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">

        <div fxLayoutAlign="start start" class="required_color">* Required </div>
        @if (isMultiPortalSupport) {
          <div>
            <p class="alignment">Please note that blocking this online account will also block the access from other
              portal(s)
            like</p>
            <ul class="alig_bullet_point">
              <li><b>{{branding?branding:'Myaccount Portal'}}</b></li>
              <li><b>{{brandingConfig[appConfig.brandName] && brandingConfig[appConfig.brandName].mobileAppName ? brandingConfig[appConfig.brandName].mobileAppName : ''}} Mobile App</b></li>
            </ul>
          </div>
        }
        <br>
          <form fxFlex="100" class="alig_form" fxLayout="column" fxLayoutAlign="start stretch"
            [formGroup]="blockAccountForm">
            <div fxLayoutAlign="start start" fxLayout="column">
              <mat-label>Select Reason for blocking online account* :</mat-label>
              <mat-form-field appearance="outline" fxFlex="100" class="mt-3">
                <mat-select required formControlName="reason" placeholder="Select Reason">
                  @for (reason of accountBlockingReasons; track reason) {
                    <mat-option [value]="reason.name">
                      {{reason.name}}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>

            @if (blockAccountForm.controls.reason.value === 'Other') {
              <div>
                <mat-label>Reason for Blocking Online Account* : </mat-label>
                <textarea [(ngModel)]="interaction.reasonText" formControlName="remark"
                  (ngModelChange)="onTextChange($event)" [attr.maxlength]="counter ? maxCharacter : ''">
                </textarea>
                <div fxLayoutAlign="end end ">
                {{numberOfCharacter}} / {{maxCharacter}}</div>
              </div>
            }
            @if (blockAccountForm.controls['remark'].dirty || blockAccountForm.controls['remark'].touched) {
              <div
                class="text-danger">
                @if (blockAccountForm.controls['remark'].errors?.required || blockAccountForm.controls['remark'].hasError('whitespace')) {
                  <div
                    >
                    Reason is required.
                  </div>
                }
              </div>
            }

            <div fxLayout="row" fxLayout_lt-sm="column" fxLayoutGap="15px">
              <span>I'm sure, block this online account* :</span>
              <mat-checkbox formControlName="checkBlockAccount" required></mat-checkbox>
            </div>
          </form>


          <mat-card-actions fxLayoutGap=" 20px" fxLayoutAlign="end end" class="align_button">
            <button class="btn btn-sm ux-icon-button" (click)="closeModal()">Cancel</button>
            <button class="btn btn-sm ux-icon-button" (click)="blockAccount()" [disabled]="blockAccountForm.invalid || apiCallPending"
            data-toggle="tooltip" title="Block Online Account">Block Online Account</button>
          </mat-card-actions>
        </mat-card-content>
      </mat-card>
    </section>
  </div>