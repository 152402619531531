import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractComponent } from '@londonhydro/ux-lib';

@Component({
  selector: 'app-assign-to-user-dialog',
  templateUrl: './assign-to-user-dialog.component.html',
  styleUrls: ['./assign-to-user-dialog.component.scss']
})
export class AssignToUserDialogComponent extends AbstractComponent implements OnInit {

  assignToUserForm: UntypedFormGroup = this.formBuilder.group({
    userId: [''],
  });
  accountsCurrent = [
    { name: 'Assign to me', value: this.loginUser.id },
    { name: 'Other user', value: 'Others' }
  ];
  userList = [];
  
  selectedOption = this.accountsCurrent[0].name;
  selectedOptionOthers = false;

  constructor(injector: Injector, public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public modalData: any,
              public formBuilder: UntypedFormBuilder) {
    super(injector);
    this.userList = modalData;
  }

  ngOnInit(): void {
    this.assignToUserForm.controls.userId.setValue(this.accountsCurrent[0].value);
  }

  closeModal(): void {
    this.dialogRef.close(null);
  }

  assignToUser(): void {
    console.log('assignToUser::', this.assignToUserForm.controls.userId);
    this.dialogRef.close(this.assignToUserForm.controls.userId.value);
  }

}
