<mat-card appearance="outlined">
	<div mat-card-content fxFlex="100" fxLayout="row"  class="p-2 m-2">
		<ng-container>
			<h2 class="pt-2 uppcase"><strong>Customer Registration</strong></h2>
			<span mat-raised-button class="btn btn-success  ml-3" (click)="gotoForm();"
			#tooltip="matTooltip"  matTooltip="Register an User" matTooltipPosition="above">
				<mat-icon aria-hidden="false">account_box</mat-icon>
				<span class="align-top">Register User</span></span>
		</ng-container>

	</div>
</mat-card>