@if (formListDS) {
  <div fxLayout="column">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-around stretch" class="p-2">
      @if (formsFilterForm) {
        <div fxLayout="row" fxLayout_lt-sm="column" [ngClass]="{'mb-4' : screensize_ltsm}" fxFlex="100" fxLayoutAlign="end center"
          fxLayoutGap="15px" class="mb-2">
          <mat-form-field class="mt-3">
            <mat-label>Quick search</mat-label>
            <input matInput class="form-field"  #quickSearchInput (keyup)="applyFilter($event.target.value)">
            <button matSuffix mat-icon-button aria-label="Clear"
              (click)="applyFilter(''); quickSearchInput.value=''">
              <mat-icon matSuffix>close</mat-icon>
            </button>
          </mat-form-field>
          <button type="button" class="btn btn-sm ux-icon-button" title="Export Data in CSV format"
            (click)="exportToCSV()" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon data-toggle="tooltip" data-placement="bottom" title="Export Data" class="mr-2">file_download
              </mat-icon><span>Export Data</span>
            </button>
          </div>
        }
        <div>
          <mat-table matTableExporter [dataSource]="formListDS" class="live-data-container mat-elevation-z8" matSort
            #exporter="matTableExporter" [ngClass]="{'mat-table-responsive' : screensize_ltmd}" 
            #manageFormsTableSort="matSort">
            <ng-container matColumnDef="index">
              <mat-header-cell *matHeaderCellDef class="sl" mat-sort-header><span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index" class="sl" [ngClass]="{'mat-cell-responsive-sm padding-left-md' : screensize_ltsm}">
              <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}">#</span><span [ngClass]="{'ml-2' : screensize_ltsm}">
            {{element.rowIndex}}</span> </mat-cell>
          </ng-container>
          @for (attribute of formAttributeConfig; track attribute; let i = $index) {
            <ng-container matColumnDef="{{attribute.name}}">
              <mat-header-cell *matHeaderCellDef class="pl-1" [class]="attribute.class"
                [ngClass]="{'d-none' : screensize_ltsm}" [ngClass]="{'mat-cell-responsive' : screensize_ltmd}" mat-sort-header>
                <div>{{attribute.label}}</div>
              </mat-header-cell>
              <mat-cell class="matCell pl-1 " [class]="attribute.class" *matCellDef="let element;"
                [ngClass]="{'mat-cell-responsive-sm' : screensize_ltsm}" [ngClass]="{'mat-cell-responsive' : screensize_ltmd}">
                @if (element[attribute.name] !=null) {
                  <span>
                    <span [ngClass]="{'d-flex' : screensize_ltsm}"><span class="d-none"
                    [ngClass]="{'mobile-label-display' : screensize_ltsm}">{{attribute.label}}:</span><span
                  [ngClass]="{'ml-2 ' : screensize_ltsm}" >{{element[attribute.name]}} </span></span>
                </span>
              }
              @if (element[attribute.name] ==null) {
                <span>-</span>
              }
            </mat-cell>
          </ng-container>
        }
        <tr class="mat-row d-flex" *matNoDataRow >
          <td class="mat-cell d-flex w-100 p-3 font-weight-bold"
            fxLayoutAlign="center" [attr.colspan]="displayedColumns.length">
            No data matching the filter.
          </td>
        </tr>
        <mat-header-row [ngClass]="{'d-none' : screensize_ltsm}" *matHeaderRowDef="displayedColumns; sticky: true;"
        class="matHeaderRow"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="matHeaderRow"
        [ngClass]="{'responsive-mat-row' : screensize_ltmd}" [ngClass]="{'responsive-mat-row-small' : screensize_ltsm}"></mat-row>
      </mat-table>
      @if (formsFilterForm) {
        <mat-paginator #formsPaginator="matPaginator" [pageSizeOptions]="[10, 25, 50]"
          showFirstLastButtons [pageSize]="10" aria-label="Select page">
        </mat-paginator>
      }
    </div>
  </div>
</div>
}