import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceRequestAdminService } from '@londonhydro/customerservice-api';
import { ServiceRequestAlert, UIUtil, ServiceRequestQueue } from '@londonhydro/utility-model';
import * as moment from 'moment-timezone';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-workflow-confirmation-dialog',
	templateUrl: './workflow-confirmation-dialog.component.html',
	styleUrls: ['./workflow-confirmation-dialog.component.scss']
})
export class WorkflowConfirmationDialogComponent implements OnInit, OnDestroy {
	title: string;
	message: string;
	formDetails: ServiceRequestQueue;
	requestAlerts: ServiceRequestAlert[];
	uiTimezone: string;
	breakPoint_observer_subscription_ltsm: Subscription;
  	screensize_ltsm: boolean;

	constructor(public dialogRef: MatDialogRef<WorkflowConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData: WorkflowConfirmationDialogData,
		private srAdminService: ServiceRequestAdminService, private breakPointObserver: BreakpointObserver) {
		this.title = modalData?.title || 'Confirmation';
		this.message = modalData?.message || 'Are you sure to proceed?';
		this.formDetails = modalData?.formDetails || null;
		this.requestAlerts = modalData?.requestAlerts || null;
		this.uiTimezone = moment(new Date()).tz(UIUtil.UiTimeZone).format('z');
		console.log("Hello", this.uiTimezone);
	}

	private fetchRequestAlerts(): void {
		this.srAdminService.getServiceRequestAlerts(this.formDetails.requestId).subscribe(
			(res: ServiceRequestAlert[]) => {
				this.requestAlerts = res;
				this.requestAlerts.forEach(element => {
					element.createdDate = moment.utc(element.createdDate).toDate();
				})

			},
			(error: any) => {
				console.log('error getFormsCountByType', error);
			}
		);
	}

	ngOnInit(): void {
		if (this.formDetails) {
			if (!this.requestAlerts || this.requestAlerts === null) {
				this.fetchRequestAlerts();
			}
		}
		this.breakPoint_observer_subscription_ltsm = this.breakPointObserver.observe([
			Breakpoints.XSmall]
		  ).subscribe(result => {
			if (result && result.matches) {
			  this.screensize_ltsm = true;
			} else {
			  this.screensize_ltsm = false;
			}
		  });
	}

	actionSubmit(): void {
		this.dialogRef.close(true);
	}

	actionCancel(): void {
		this.dialogRef.close();
	}

	ngOnDestroy(): void {
		if (this.breakPoint_observer_subscription_ltsm) {
			this.breakPoint_observer_subscription_ltsm.unsubscribe();
		  }
	}
}

export class WorkflowConfirmationDialogData {
	constructor(public title: string, public message: string, public formDetails: ServiceRequestQueue,
		public requestAlerts?: ServiceRequestAlert[]) {
	}
}
