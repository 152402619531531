<div id="modal-content-wrapper">
  <section id="modal-body">

    <mat-card appearance="outlined" class="ux-card border border-light p-0">
      <mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title>Unblock Online Account</mat-card-title>
        <button (click)="closeModal()" class="btn btn-link " type="button" id="dismiss" aria-label="Close">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">
        <div fxLayoutAlign="start start" class="required_color">* Required </div>
        @if (isMultiPortalSupport) {
          <div>
            <p class="alignment">Please note that unblocking this online account will allow the user to access the below listed portal(s) like
            </p>
            <ul class="alig_bullet_point">
              <li><b>{{branding?branding:'Myaccount Portal'}}</b></li>
              <li><b>{{brandingConfig[appConfig.brandName] && brandingConfig[appConfig.brandName].mobileAppName ? brandingConfig[appConfig.brandName].mobileAppName : ''}} Mobile App</b></li>
            </ul>
          </div>
        }
        <br>
          <form class="alig_form" fxLayout="column" [formGroup]="unBlockAccount">
            <div fxLayout="column" fxLayoutAlign="start start">
              <mat-label for="message">Reason for unblocking online account* :</mat-label>
              <textarea [(ngModel)]="interaction.reasonText" (ngModelChange)="onTextChange($event)"
                formControlName="reason" [attr.maxlength]="counter ? maxCharacter : ''" required>
              </textarea>
            </div>
            <div fxLayoutAlign="end end">
              {{numberOfCharacter}} / {{maxCharacter}}
            </div>
            @if (unBlockAccount.controls['reason'].dirty || unBlockAccount.controls['reason'].touched) {
              <div
                class="text-danger">
                @if (unBlockAccount.controls['reason'].errors?.required || unBlockAccount.controls['reason'].hasError('whitespace')) {
                  <div
                    >
                    Reason is required.
                  </div>
                }
              </div>
            }
            <div xLayoutAlign="start start">
              <mat-label for="check">I'm sure, unblock this online account* :</mat-label>
              <mat-checkbox class="ml-3" formControlName="checkUnblockAccount" required>
              </mat-checkbox>
            </div>
          </form>
          <mat-card-actions fxLayoutGap=" 20px" fxLayoutAlign="end end" class="align_button">
            <button class="btn btn-sm ux-icon-button" (click)="closeModal()">Cancel</button>
            <button class="btn btn-sm ux-icon-button" (click)="unblockAccount()" [disabled]="unBlockAccount.invalid || apiCallPending"
            data-toggle="tooltip" title="Unblock ">Unblock Online Account</button>
          </mat-card-actions>
        </mat-card-content>

      </mat-card>
    </section>
  </div>
