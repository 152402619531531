import { Component, OnInit, Injector, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'underscore';
import { MenuItem, WhitelabelComponent } from '@londonhydro/ux-lib';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-csr-home',
  templateUrl: './csr-home.component.html',
  styleUrls: ['./csr-home.component.scss']
})
export class CsrHomeComponent extends WhitelabelComponent implements OnInit, OnDestroy {

  selectedMenu: MenuItem;
  title: string;
  idleState = "NOT_STARTED";
  countdown?: number = null;

  constructor(injector: Injector, private idle: Idle, private cd: ChangeDetectorRef) {
    super(injector);
  }

  brandingConfigLoaded(): void {
    if (this.brandingConfig.hasOwnProperty('idleTimeout') && this.brandingConfig.idleTimeout !== null && this.brandingConfig.idleTimeout > 0) {
      this.idle.setIdle(this.brandingConfig.idleTimeout); //  in seconds
      this.idle.setTimeout(5); // in seconds
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  
      this.idle.onIdleStart.subscribe(() => {
        this.idleState = "IDLE";
      });
      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = "NOT_IDLE";
        this.countdown = null;
        this.cd.detectChanges();
      });
      this.idle.onTimeout.subscribe(() => {
          this.idleState = "TIMED_OUT"
          this.userSessionService.redirectToLogout();
        });
      this.idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);
      this.resetTimers();
    }
  }
  ngOnInit(): void {
    super.ngOnInit();
  }

  menuChangeHandler(sMenu: MenuItem): void {
    console.log('menuChangeHandler::::', sMenu);
    this.selectedMenu = sMenu;
    if (this.selectedMenu.title !== 'blank'){
    this.title = this.selectedMenu.title;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  resetTimers() {
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
  }

  isV2(): boolean{
    return environment.iam.loginUrl.toLowerCase().includes('v2') ? true : false;
  }

}
