<div>
  <section>

    <mat-card appearance="outlined" class="ux-card border border-light p-0">
      <mat-card-header class=" p-2" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title>Additional Data Viewer</mat-card-title>
        <button (click)="closeModal()" class="btn btn-link text-secondary" type="button" id="dismiss"
          aria-label="Close">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">

        <mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">

          @if (userData && ((userData.actual && userData.actual.challenge) || userData.challenge)) {
            <mat-accordion class="border border-light p-0 mt-2">
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
                  fxLayoutAlign="space-between center">
                  <mat-panel-title class="mb-0 text-white font-weight-bold">User Input Details
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
                  @if (actualList) {
                    <div fxFlex="100" fxLayout="row wrap" fxLayout_xs="column"
                      fxLayoutAlign="space-between center" fxLayoutAlign_xs="start">
                      @for (item of actualList | keyvalue; track item) {
                        <div fxFlex="50" fxFlex_xs="100"  class="pt-2 pb-2">
                          <mat-label fxFlex="45" fxFlex_xs="100"> <span>{{item.value[0] | labelTranslate}}: </span></mat-label>
                          @if (item.value[1]) {
                            <mat-label fxFlex="55" fxFlex_xs="100" class="font-weight-bold" [class]="item.value[1].includes(',')?' text-break ':''">
                              <!-- {{item.value[1].includes(',')?item.value[1].trim().split(',').join(', '):item.value[1]}} -->
                              {{calcValue(item, false)}}
                            </mat-label>
                          }
                          @else {
                            <mat-label fxFlex="55" fxFlex_xs="100" class="font-weight-bold">-</mat-label>
                          }
                        </div>
                      }
                    </div>
                  }
                </mat-card-content>
              </mat-expansion-panel>
            </mat-accordion>
          }

          @if (userData && userData.expected) {
            <mat-accordion class="border border-light p-0 mt-2">
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
                  fxLayoutAlign="space-between center">
                  <mat-panel-title class="mb-0 text-white font-weight-bold">Expected Details
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
                  @if (expectedList) {
                    <div fxFlex="100" fxLayout="row wrap" fxLayout_xs="column" fxLayoutAlign_xs="start"
                      fxLayoutAlign="space-between center">
                      @for (item of expectedList | keyvalue; track item) {
                        <div fxFlex_xs="100" fxFlex="50" class="pt-2 pb-2">
                          <mat-label fxFlex="45" fxFlex_xs="100" fxLayoutAlign="start baseline"> <span>{{item.value[0] | labelTranslate}}: </span></mat-label>
                          @if (item.value[1]?.value1) {
                            <mat-label fxFlex="55" fxFlex_xs="100" class="font-weight-bold" [class]="item.value[1].value1.includes(',')?' text-break ':''">
                              <!-- {{item.value[1].value1.includes(',')?item.value[1].value1.trim().split(',').join(', '):item.value[1].value1}}  -->
                              {{calcValue(item, true)}}
                            </mat-label>
                          }
                          @else {
                            <mat-label fxFlex="55" fxFlex_xs="100" class="font-weight-bold">-</mat-label>
                          }
                        </div>
                      }
                    </div>
                  }
                </mat-card-content>
              </mat-expansion-panel>
            </mat-accordion>
          }
        </mat-card-content>
      </mat-card-content>
    </mat-card>
  </section>
</div>