import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IamUserTxnService } from '@londonhydro/iam-api';
import { AbstractComponent, BrandingConfig, BRANDING_CONFIG, AppConfg, APP_CONIG} from '@londonhydro/ux-lib';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-block-account-form',
  templateUrl: './block-account-form.component.html',
  styleUrls: ['./block-account-form.component.scss']
})

export class BlockAccountFormComponent extends AbstractComponent implements OnInit, OnDestroy {
  branding: string;
  numberOfCharacter = 0;
  maxCharacter = 250;
  interaction = {
    reasonText: ''
  };
  counter = true;
  accountBlockingReasons = [
    { name: 'Payment Arrangement Misuse', value: '1' },
    { name: 'Account Under Investigation', value: '2' },
    { name: 'Other', value: '3' }
  ];

  blockAccountForm: UntypedFormGroup = this.formBuilder.group({
    checkBlockAccount: ['', [Validators.required]],
    reason: ['', [Validators.required]],
    remark: [''],
  });

  isBlockAccount: false;
  notYetRegistered: boolean;
  userStatus: any;
  successFlag: boolean;
  errorFlag: boolean;
  mailSent = false;
  userId: any;
  dropDownSelected = false;
  isMultiPortalSupport = true;
  apiCallPending = false;

  constructor(injector: Injector, private toastr: ToastrService, public formBuilder: UntypedFormBuilder,
    private iamUserTxnService: IamUserTxnService, public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public userData: any,
    @Inject(BRANDING_CONFIG) public brandingConfig: BrandingConfig,
    @Inject(APP_CONIG) public appConfig: AppConfg) {
    super(injector);
    this.branding = this.userData.siteName;
  }

  ngOnInit(): void {
    this.isMultiPortalSupport = this.brandingConfig[this.appConfig['brandName']]['multiplePortalSupport'] ?? true;
    this.blockAccountForm.controls.reason.valueChanges.subscribe(newVal => {
      if(newVal == 'Other'){
        this.blockAccountForm.controls.remark.setValidators([Validators.required, this.noWhitespaceValidator]);
      }
      else {
        this.blockAccountForm.controls.remark.setValidators(null);
        this.blockAccountForm.controls.remark.setValue('');
        
      }
    })
  }

  noWhitespaceValidator(control: UntypedFormControl): any {
    return (control.value || '').trim().length? null : { 'whitespace': true };       
  }

  userSessionLoaded(): void {
  }

  isLoginUserWaterCSR(): boolean {
    if (this.loginUser == null) {
      return false;
    }
    return this.loginUser.hasWaterCsrRole;
  }

  isLoginUserCSR(): boolean {
    if (this.loginUser == null) {
      return false;
    }
    return this.loginUser.hasCsrRole;
  }

  resetAlertFlags(): void {
    this.successFlag = false;
    this.errorFlag = false;
  }

  onTextChange(reasonText: string): void {
    this.numberOfCharacter = reasonText.length;
  }

  blockAccount(): void {
    console.log(this.blockAccountForm.controls.remark);
    const params = JSON.parse(JSON.stringify({
      remark: this.blockAccountForm.controls.reason.value === 'Other' ? this.blockAccountForm.controls.remark.value: this.blockAccountForm.controls.reason.value
    }));
    this.apiCallPending = true;
    this.iamUserTxnService.blockUserAccount(this.userData.data.id, params).pipe(
      catchError(error => {
        this.errorFlag = true;
        this.closeModal('error');
        this.apiCallPending = false;
        this.resetAlertFlags();
        this.toastr.error('Error occured while blocking account.', 'Error', {
          timeOut: 10000,
        });

        return of(null);
      })).subscribe((response: any) => {
        console.log('response', response);
        this.userStatus = response.status.value;
        this.mailSent = true;
        this.successFlag = true;
        this.resetAlertFlags();
        this.toastr.success('You have successfully blocked the account ', 'Success', {
          timeOut: 10000,
        });
        this.closeModal('success');
        this.apiCallPending = false;
        this.resetAlertFlags();

      });

  }
  closeModal(str?: string): void {
    this.dialogRef.close(str);
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}


