import { HttpParams } from '@angular/common/http';
import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { CustomerLookupFormComponent } from '../customer-lookup-form/customer-lookup-form.component';
import { validateEmailFactory } from '@londonhydro/ux-lib';


@Component({
  selector: 'app-cust-lookup-change-email-form',
  templateUrl: './cust-lookup-change-email-form.component.html',
  styleUrls: ['./cust-lookup-change-email-form.component.scss']
})
export class CustLookupChangeEmailFormComponent extends AbstractComponent implements OnInit, OnDestroy {
  customerDetails: any;
  submitted = false;
  pageLoader: boolean;
  customerId: any;
  userId: any;
  loginEmailChangeForm: UntypedFormGroup = this.formBuilder.group({
    loginEmailAddress: ['',
      [Validators.required, Validators.minLength(6), validateEmailFactory()]]
  });

  constructor(injector: Injector, private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<CustomerLookupFormComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  accountDataLoaded(): void {
  }

  editLoginEmail(): void {
    this.submitted = true;
    this.pageLoader = true;
    const params = new HttpParams()
      .set('applicationName', 'MyLondonHydro')
      .set('applicationUrl', window.location.origin)
      .set('email', this.loginEmailChangeForm.get('loginEmailAddress').value);

    const paramsObject = params.keys().reduce((object, key) => {
      object[key] = params.get(key);
      return object;
    }, {});
  }

  closeModal(): void {
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
