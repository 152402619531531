import { Component, OnInit, OnDestroy, Inject, Injector } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IamUserTxnService } from '@londonhydro/iam-api';
import { AbstractComponent } from '@londonhydro/ux-lib';
import * as _ from 'underscore';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { validateEmailFactory } from '@londonhydro/ux-lib';
import { IamLabelPipe } from '../../framework/shared/service/iam-label.pipe';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-change-login-email-form',
  templateUrl: './change-login-email-form.component.html',
  styleUrls: ['./change-login-email-form.component.scss']
})
export class ChangeLoginEmailFormComponent extends AbstractComponent implements OnInit, OnDestroy {

  customerDetails: any;
  submitted = false;
  pageLoader: boolean;
  customerId: any;
  userId: any;
  loginEmailChangeForm: UntypedFormGroup;
  breakPoint_observer_subscription_ltsm: Subscription;
  screensize_ltsm: boolean;


  constructor(injector: Injector, private formBuilder: UntypedFormBuilder, private toastr: ToastrService,
    private iamUserTxnService: IamUserTxnService, public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public userData: any, private iamLabelPipe: IamLabelPipe, private breakPointObserver: BreakpointObserver) {
    super(injector);
    this.resetChangeEmailForm();
  }

  resetChangeEmailForm(): void {
    this.loginEmailChangeForm = this.formBuilder.group({
      loginEmailAddress: ['', [Validators.required, Validators.maxLength(40), validateEmailFactory()]],
      confirmEmailAddress: ['', [Validators.required, Validators.maxLength(40), validateEmailFactory()]]
    }, {
      validator: [this.confirmAndLoginEmailMatchValidator, this.newEmailSameAsCurrentValidator
        , this.confirmEmailSameAsCurrentValidator]
    });
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.breakPoint_observer_subscription_ltsm = this.breakPointObserver.observe([
      Breakpoints.XSmall]
    ).subscribe(result => {
      if (result && result.matches) {
        this.screensize_ltsm = true;
      } else {
        this.screensize_ltsm = false;
      }
    });

  }

  userSessionLoaded(): void {

  }

  newEmailSameAsCurrentValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const newEmail = control.get('loginEmailAddress');
    const confirmEmail = control.get('confirmEmailAddress');
    return newEmail ?.value === this.userData.username
      || newEmail ?.value === confirmEmail ?.value && (newEmail ?.value === this.userData.username
        && confirmEmail ?.value === this.userData.username ) ? { newEmailSameAsCurrentEmail: true } : null;
  }

  confirmEmailSameAsCurrentValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const newEmail = control.get('loginEmailAddress');
    const confirmEmail = control.get('confirmEmailAddress');
    return confirmEmail ?.value === this.userData.username
      || newEmail ?.value === confirmEmail ?.value && (newEmail ?.value === this.userData.username
        && confirmEmail ?.value === this.userData.username ) ? { confirmEmailSameAsCurrentEmail: true } : null;
  }


  confirmAndLoginEmailMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const newEmail = control.get('loginEmailAddress');
    const confirmEmail = control.get('confirmEmailAddress');
    return newEmail ?.value !== confirmEmail ?.value && !this.loginEmailChangeForm.errors ?.newEmailSameAsCurrentEmail
      && !this.loginEmailChangeForm.errors ?.confirmEmailSameAsCurrentEmail ? { newAndConfirmEmailNotSame: true } : null;
  }

  editLoginEmail(): void {
    if (this.loginEmailChangeForm.controls['loginEmailAddress'].value === this.loginEmailChangeForm.controls['confirmEmailAddress'].value) {
      this.submitted = true;
      this.pageLoader = true;
      const params = JSON.parse(JSON.stringify({
        email: this.loginEmailChangeForm.controls.loginEmailAddress.value
      }));

      this.iamUserTxnService.changeLoginEmail(this.userData.id, params).pipe(
        catchError(error => {
          this.toastr.error('Unable to initiate your email address change. ' + (error.messageCode ? this.iamLabelPipe.transform(error.messageCode) : ''), 'Error', {
            timeOut: 10000,
          });
          this.pageLoader = false;
          this.closeModal();
          return of(null);
        })).subscribe(
          res => {
            console.log('changeLoginEmail', res);
            if (res) {
              this.toastr.success('Login email change has been initiated. The system will be updated once the customer approves the change.',
                'Success', {
                timeOut: 10000,
              });
              this.pageLoader = false;
              this.closeModal('success');
            }
          });
    } else {
      this.toastr.error('Email Address and Confirm Email Address should be same.', 'Error', {
        timeOut: 10000,
      });
    }
  }

  ngOnDestroy(): void {
    console.log('On child component class::::ngOnDestroy');
    if (this.breakPoint_observer_subscription_ltsm) {
      this.breakPoint_observer_subscription_ltsm.unsubscribe();
    }
    super.ngOnDestroy();
  }

  closeModal(str?: string): void {
    this.dialogRef.close(str);
  }
}



