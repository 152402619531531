import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from '@londonhydro/ux-lib';

@Component({
  selector: 'app-unblock-account',
  templateUrl: './unblock-account.component.html',
  styleUrls: ['./unblock-account.component.scss']
})
export class UnblockAccountComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(injector: Injector, route: ActivatedRoute
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy(): void {
  }
}
