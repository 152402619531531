import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog,  MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExportToCsv } from 'export-to-csv';
import { LocationDataService } from '../../backend/csr/dataservice/location.dataservice';
import * as _ from 'underscore';
import { Permits } from '@londonhydro/utility-model';
import { ServiceLocation } from '@londonhydro/utility-model';
import { AddressViewerComponent } from './address-viewer/address-viewer.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-service-location',
  templateUrl: './service-location.component.html',
  styleUrls: ['./service-location.component.scss']
})
export class ServiceLocationComponent implements OnInit, OnDestroy {

  @ViewChild('formsPaginator') formsPaginator: MatPaginator;
  allForms: Permits[] = [];
  formListDS: MatTableDataSource<any> = null;
  allAddressForms: ServiceLocation[] = [];
  displayedColumns: string[];
  breakPoint_observer_subscription_ltsm: Subscription;
  breakPoint_observer_subscription_ltmd: Subscription;
  screensize_ltsm: boolean;
  screensize_ltmd: boolean;
  
  attributeName: string;

  selectedFilter = 'ALL';

  selectedQlink: Permits;

  selectedFilterType = 'address';

  @ViewChild('serviceReqTableSort') sort: MatSort;

  formAttributeConfig: any[] = [
    { name: 'permitNumber', label: 'Permit Number', type: 'string', class: 'permitNumber' },
    { name: 'permitName', label: 'Permit Name', type: 'string', class: 'permitName' },
    { name: 'action', label: 'Action', type: 'permitAction', class: 'permitAction' }

  ];
  constructor(private locationDataService: LocationDataService, private matDialog: MatDialog, private datePipe: DatePipe,
    private breakPointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.formListDS = new MatTableDataSource<any>([]);
    this.displayedColumns = _.union(['index'], _.pluck(this.formAttributeConfig, 'name'));
    this.getPermitesData();

    this.breakPoint_observer_subscription_ltsm = this.breakPointObserver.observe([
      Breakpoints.XSmall]
    ).subscribe(result => {
      if (result && result.matches) {
        this.screensize_ltsm = true;
      } else {
        this.screensize_ltsm = false;
      }
    });

    this.breakPoint_observer_subscription_ltmd = this.breakPointObserver.observe([
      Breakpoints.Small,Breakpoints.XSmall]
    ).subscribe(result => {
      if (result && result.matches) {
        this.screensize_ltmd = true;
      } else {
        this.screensize_ltmd = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.formListDS.sort = this.sort;
  }

  viewLocation(data: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component' + data.premiseId;
    dialogConfig.width = '920px';
    dialogConfig.height = 'auto';
    dialogConfig.data = { addresses: this.allAddressForms, permitNumber: data.permitNumber, permitName: data.permitName };
    const addressViewer = this.matDialog.open(AddressViewerComponent, dialogConfig);
  }

  getServiceLocationData(data: any): void {
    this.locationDataService.getServiceLocationData(data.permitNumber).subscribe(
      (res: any) => {

        _.each(res, (element, index) => {
          element.rowIndex = (index + 1);
          this.allAddressForms.push(_.extend(new ServiceLocation(), element));
        });
        this.viewLocation(data);
      },
      (error: any) => {
        console.log('error getServiceLocationData()', error);
      }
    );
  }
  getPermitesData(): void {
    this.locationDataService.getPermitesData().subscribe(
      (res: any) => {

        _.each(res, (element, index) => element.rowIndex = (index + 1));
        this.allForms = res;
        this.formListDS.data = this.allForms;
        setTimeout(() => this.formListDS.paginator = this.formsPaginator);
      },
      (error: any) => {
        console.log('error getPermitesData()', error);
      }
    );
  }
  
  applyFilter(filterValue: string): any {
    this.formListDS.filter = filterValue.trim().toLowerCase();
    this.formListDS.filteredData.forEach(
      (res: any, count) => {
        res.rowIndex = count + 1;
      });
    if (this.formListDS.paginator) {
      this.formListDS.paginator.firstPage();
    }
  }

  exportToCSV(): void {
    const tableDataCSV = [];

    this.formListDS.filteredData.forEach((element) => {
      const configHeader = this.formAttributeConfig;
      const tempObj: any = {};
      for (const attribute of configHeader) {
        let val = element[attribute.name];
        const key = attribute.label;
        tempObj[key] = val ? val : '';

      }
      tableDataCSV.push(tempObj);

    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'Permits_' + this.datePipe.transform(new Date(), 'shortDate').split('/').join('.')

    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(tableDataCSV);
  }

  ngOnDestroy(): void {
    if (this.breakPoint_observer_subscription_ltmd) {
      this.breakPoint_observer_subscription_ltmd.unsubscribe();
    }
    if (this.breakPoint_observer_subscription_ltsm) {
      this.breakPoint_observer_subscription_ltsm.unsubscribe();
    }
  }
}
