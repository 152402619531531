import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractComponent, APP_CONIG, AppConfg} from '@londonhydro/ux-lib';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountDataService } from '@londonhydro/account-api';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-link-customer-form',
  templateUrl: './link-customer.component.html',
  styleUrls: ['./link-customer.component.scss']
})
export class LinkCustomerComponent extends AbstractComponent implements OnInit, OnDestroy {

  submitted = false;
  pageLoader: boolean;
  customerId: any;
  linkCustomerForm: UntypedFormGroup;
  parentCustomerId: any;
  maxDigits: number;
  breakPoint_observer_subscription_ltsm: Subscription;
  screensize_ltsm: boolean;

  constructor(
    injector: Injector,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public userData: any, private accountDataService: AccountDataService, 
    private breakPointObserver: BreakpointObserver,
    @Inject(APP_CONIG) public appConfig: AppConfg) {
    super(injector);
    this.resetLinkCustomerForm();
  }

  resetLinkCustomerForm(): void {
    this.linkCustomerForm = this.formBuilder.group({
      customerId: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.parentCustomerId = '';
    this.maxDigits = this.appConfig['linkCustomer'] && this.appConfig['linkCustomer']['maxDigits'] ? this.appConfig['linkCustomer']['maxDigits'] : 6;
    if (this.userData && this.userData.data && this.userData.data.id) {
      this.parentCustomerId = this.userData.data.id;

      this.breakPoint_observer_subscription_ltsm = this.breakPointObserver.observe([
      Breakpoints.XSmall]).subscribe(result => {
        if (result && result.matches) {
          this.screensize_ltsm = true;
        } else {
          this.screensize_ltsm = false;
        }
      });

    }
  }

  linkCustomer(): void {
    this.submitted = true;
    this.pageLoader = true;
    const params = JSON.parse(JSON.stringify({
      customerId: this.linkCustomerForm.controls.customerId.value
    }));

    if (this.parentCustomerId !== this.linkCustomerForm.controls.customerId.value.trim()){
      this.accountDataService.getLinkCustomer(this.parentCustomerId, params);
      this.accountDataService.linkCustomer.pipe(
      catchError(error => {
        this.pageLoader = false;
        this.closeModal();
        if (error && error.message){
          this.toastr.error( error.message, 'Error', {
            timeOut: 5000,
          });
        } else {
          this.toastr.error('Error occured while unlinking customer.', 'Error', {
            timeOut: 5000,
          });
        }
        return of(null);
      })).subscribe((response: any) => {
        if (response && response.message){
          this.toastr.success( response.message , 'Success', {
            timeOut: 5000,
          });
          this.closeModal('success', this.linkCustomerForm.controls.customerId.value.trim());
        }
        this.pageLoader = false;
      });
    }
  }

  ngOnDestroy(): void {
    console.log('On child component class::::ngOnDestroy');
    if (this.breakPoint_observer_subscription_ltsm) {
      this.breakPoint_observer_subscription_ltsm.unsubscribe();
    }
    super.ngOnDestroy();
  }

  closeModal(str?: string , childId?: string): void {
    const data = childId ? { msg : str , childId : childId} : { msg : str};
    this.dialogRef.close(data);
  }
}



