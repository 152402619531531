import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ModelObject } from '@londonhydro/api-core-angular';
import { Address, Customer, PhoneNumber, ServiceRequestQueue, User, Audit } from '@londonhydro/utility-model';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError, timer, of } from 'rxjs';
import { catchError, map, tap, retry, retryWhen, concatMap, delay } from 'rxjs/operators';
import * as _ from 'underscore';

import { AbstractService } from '../../../framework/service/abstract.service';
import { environment } from '../../../../environments/environment';

export enum PhoneNumberType {
  NOTIFICATION = 'NOTIFICATION',
  BUSINESS = 'BUSINESS/WORK',
  CELL = 'CELL',
  RESIDENTIAL = 'RESIDENTIAL'
}

@Injectable({
  providedIn: 'root'
})
export class CsrDataService extends AbstractService {

  constructor(@Inject('_CSR_MODULE_CONFIG_') _config: any, private http: HttpClient, private cookieService: CookieService) {
    super(_config);
  }

  getMenuList(): Observable<any> {
    const apiUrl = '../../../../../assets/menuList.json';
    return this.http.get<any>(apiUrl)
      .pipe(
        tap(res => {
          console.log('fetched Menu List:', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getBrandingList(): Observable<any> {
    const apiUrl = environment.brandingUrl;
    return this.http.get<any>(apiUrl)
      .pipe(
        tap(res => {
          console.log('fetched Menu List:', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
  getSettingsList(): Observable<any> {
    const apiUrl = '../../../../../settings/settings.json';
    return this.http.get<any>(apiUrl)
      .pipe(
        tap(res => {
          console.log('fetched Menu List:', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
  getIamEnJsonList(url: string): Observable<any> {
    const apiUrl = url + 'iam_en.json';
    return this.http.get<any>(apiUrl)
      .pipe(
        tap(res => {
          console.log('fetched Menu List:', res);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  postError(searchKey: string, forceSearch: boolean, syncChild: boolean, msg?: string, retryCount?: number) {
    if (environment.postErrorEndpoint != null || environment.postErrorEndpoint !== '' || environment.postErrorEndpoint !== undefined) {
      (document.getElementById("hidden_img")as HTMLImageElement).src = environment.postErrorEndpoint + "?msg=" + msg + "&retryCount=" + retryCount + "&customerId=" + searchKey;
    }
}
}
