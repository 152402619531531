import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExportToCsv } from 'export-to-csv';
import * as _ from 'underscore';
import { ServiceLocationComponent } from '../service-location.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-address-viewer',
  templateUrl: './address-viewer.component.html',
  styleUrls: ['./address-viewer.component.scss']
})
export class AddressViewerComponent implements OnInit, OnDestroy {

  @ViewChild('formsPaginator') formsPaginator: MatPaginator;
  displayedColumns: string[];
  formListDS: MatTableDataSource<any> = null;
  permitNumber: string;
  permitName: string;
  breakPoint_observer_subscription_ltsm: Subscription;
  breakPoint_observer_subscription_ltmd: Subscription;
  screensize_ltsm: boolean;
  screensize_ltmd: boolean;

  @ViewChild('serviceReqTableSort') sort: MatSort;

  formAttributeConfig: any[] = [
    { name: 'serviceAddress', label: 'Address', type: 'string' ,class:'permitServiceAddress'},
    { name: 'defaultServiceType', label: 'Default Service Type', type: 'string',class:'permitsDefaultServiceType' },
    { name: 'lotNumber', label: 'Lot Number', type: 'string',class:'permitsLotNumber' }

  ];
  wrapperMatCellContainer: string;
  wrapperText: string;
  attributeName: string;


  constructor(public dialogRef: MatDialogRef<ServiceLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any, private datePipe: DatePipe, private breakPointObserver: BreakpointObserver) {
    this.permitNumber = modalData.permitNumber;
    this.permitName = modalData.permitName;
  }

  applyFilter(filterValue: string): any {
    this.formListDS.filter = filterValue.trim().toLowerCase();
    this.formListDS.filteredData.forEach(
      (res: any, count) => {
        res.rowIndex = count + 1;
      });
    if (this.formListDS.paginator) {
      this.formListDS.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    this.formListDS = new MatTableDataSource<any>([]);
    this.displayedColumns = _.union(['index'], _.pluck(this.formAttributeConfig, 'name'));
    this.formListDS.data = this.modalData.addresses;
    setTimeout(() => this.formListDS.paginator = this.formsPaginator);

    this.breakPoint_observer_subscription_ltsm = this.breakPointObserver.observe([
      Breakpoints.XSmall]
    ).subscribe(result => {
      if (result && result.matches) {
        this.screensize_ltsm = true;
      } else {
        this.screensize_ltsm = false;
      }
    });

    this.breakPoint_observer_subscription_ltmd = this.breakPointObserver.observe([
      Breakpoints.Small,Breakpoints.XSmall]
    ).subscribe(result => {
      if (result && result.matches) {
        this.screensize_ltmd = true;
      } else {
        this.screensize_ltmd = false;
      }
    });
  }
  ngAfterViewInit(): void {
    this.formListDS.sort = this.sort;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  exportToCSV(): void {
    const tableDataCSV = [];

    this.formListDS.filteredData.forEach((element) => {
      const configHeader = this.formAttributeConfig;
      const tempObj: any = {};
      for (const attribute of configHeader) {
        let val = element[attribute.name];
        const key = attribute.label;
        tempObj[key] = val ? val : '';

      }
      tableDataCSV.push(tempObj);

    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'Permits_Address_' + this.datePipe.transform(new Date(), 'shortDate').split('/').join('.')

    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(tableDataCSV);
  }

  ngOnDestroy(): void {
    if (this.breakPoint_observer_subscription_ltmd) {
      this.breakPoint_observer_subscription_ltmd.unsubscribe();
    }
    if (this.breakPoint_observer_subscription_ltsm) {
      this.breakPoint_observer_subscription_ltsm.unsubscribe();
    }
  }

}
