import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from '@londonhydro/ux-lib';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent  extends AbstractComponent implements OnInit, OnDestroy {

  constructor(injector: Injector, router: Router, route: ActivatedRoute,
              ) {
      super(injector);
    }

  ngOnInit(): void {
  }

  editProfileForm(): void {
    this.router.navigate(['/csrapp/edit-profile-form']);
  }

  ngOnDestroy(): void {
  }
}
