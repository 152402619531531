<div id="modal-content-wrapper">
<section id="modal-body">		
<mat-card appearance="outlined" class="ux-card border border-light p-0">
			<mat-card-header class="bg-light p-2" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title>Change Email Address</mat-card-title>
				<button (click)="closeModal()" class="btn btn-link text-secondary" type="button" id="dismiss" aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>
	<mat-card-content  class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">

	<div fxLayoutAlign="end end">* required </div>
	<form fxflex="100" fxLayout="column" [formGroup]="loginEmailChangeForm">
		<div fxflex="100">
				<mat-label for="login-email-address">Login Email Address: * </mat-label>
				<mat-form-field appearance="outline">
				<input matInput type="email" id="login-email-address" 
					formControlName="loginEmailAddress">
					</mat-form-field>
		</div>
		<div fxflex="100">
			<p>
				Changes to user's Login Email Address will only be finalized once user clicks on the authentication link
				in the email that will be sent to the new email address you provide. An email will also be sent to
				user's current Login Email Address to remind or notify the user of this change.
			</p>
		</div>
	</form>
</mat-card-content>
<mat-card-footer fxLayoutGap="20px" fxLayoutAlign="end end">
	<button class="btn btn-sm ux-icon-button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-sm ux-icon-button" (click)="editLoginEmail()">Save</button>
	</mat-card-footer>
	</mat-card>
	</section>
</div>