<div id="modal-content-wrapper">
  <section id="modal-body">
    <mat-card appearance="outlined" class="ux-card border border-light p-0">
      <mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title>Address Viewer</mat-card-title>
        <button (click)="closeModal()" class="btn btn-link text-secondary" type="button" id="dismiss"
          aria-label="Close">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
      </mat-card-header>

      <mat-card-content class="content-body p-2 " [ngClass]="{'address-viewer-content' : screensize_ltsm}" fxLayout="column" fxLayoutAlign="start stretch">

        <div fxFlex="100" fxLayout="row" fxLayoutAlign="flex-end">
          <div fxFlex="90" fxLayout="row" fxLayout_xs="column" fxLayoutAlign="space-around center" fxLayoutAlign_xs="start stretch"  fxLayoutGap_xs="10px" fxLayoutGap="100px">
            <div fxFlex="50" fxFlex_xs="100">
              <span fxFlex="30">Permit Name: </span>
              <span class="font-weight-bold" fxFlex="70">{{permitName}}</span>
            </div>
            <div fxFlex="50" fxFlex_xs="100">
              <span fxFlex="30">Permit Number: </span>
              <span class="font-weight-bold" fxFlex="70">{{permitNumber}}</span>
            </div>
          </div>
        </div>
        <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-around stretch" class="p-4">
          <div fxLayout="row" fxLayout_lt-sm="column" [ngClass]="{'mb-4' : screensize_ltsm}" fxFlex="100"
            fxLayoutAlign="end center" fxLayoutGap="15px" class="mb-2">
            <mat-form-field class="mt-3">
              <mat-label>Quick search</mat-label>
              <input matInput class="form-field" #quickSearchInput
                (keyup)="applyFilter($event.target.value)">
              <button matSuffix mat-icon-button aria-label="Clear"
                (click)="applyFilter(''); quickSearchInput.value=''">
                <mat-icon matSuffix>close</mat-icon>
              </button>
            </mat-form-field>

            <button type="button" class="btn btn-sm ux-icon-button" title="Export Data in CSV format"
              (click)="exportToCSV()" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon data-toggle="tooltip" data-placement="bottom" title="Export Data" class="mr-2">
                file_download
                </mat-icon><span>Export Data</span>
              </button>

            </div>
          </div>
          <div>

            <mat-table matTableExporter [dataSource]="formListDS" class="live-data-container mat-elevation-z8"
              matSort #serviceReqTableSort="matSort" #exporter="matTableExporter"
              [ngClass]="{'mat-table-responsive' : screensize_ltsm}">
              <ng-container matColumnDef="index">
                <mat-header-cell *matHeaderCellDef class="sl" mat-sort-header>
                  <span>#</span></mat-header-cell>

                  <mat-cell *matCellDef="let element; let i = index"
                    [ngClass]="{'mat-cell-responsive-sm padding-left-md' : screensize_ltsm}" class="sl"> <span class="d-none"
                  [ngClass]="{'mobile-label-display' : screensize_ltsm}">#</span><span [ngClass]="{'ml-2' : screensize_ltsm}">
                {{element.rowIndex}}</span> </mat-cell>
              </ng-container>

              @for (attribute of formAttributeConfig; track attribute) {
                <ng-container matColumnDef="{{attribute.name}}">
                  <mat-header-cell *matHeaderCellDef class="pl-1"  [class]="attribute.class"
                    [ngClass]="{'d-none' : screensize_ltsm}" mat-sort-header>
                    <div>{{attribute.label}}</div>
                  </mat-header-cell>
                  <mat-cell class="matCell pl-1" [class]="attribute.class" *matCellDef="let element;"
                    [ngClass]="{'mat-cell-responsive-sm' : screensize_ltsm}">
                    @if (element[attribute.name] !=null) {
                      <span>
                        <span [ngClass]="{'d-flex' : screensize_ltsm}"><span class="d-none"
                        [ngClass]="{'mobile-label-display' : screensize_ltsm}">{{attribute.label}}:</span><span
                        [ngClass]="{'ml-2 text-break' : screensize_ltsm}">{{element[attribute.name]}}
                      </span></span>
                    </span>
                  }
                  @if (element[attribute.name] ==null || element[attribute.name].length==0) {
                    <span><span
                      class="d-none"
                    [ngClass]="{'mobile-label-display' : screensize_ltsm}">{{attribute.label}}:</span>-</span>
                  }
                </mat-cell>
              </ng-container>
            }

            <mat-row class="mat-row" *matNoDataRow>
              <mat-cell class="mat-cell" [attr.colspan]="displayedColumns.length">
                No data matching the filter.
              </mat-cell>
            </mat-row>
            <mat-header-row [ngClass]="{'d-none' : screensize_ltsm}" *matHeaderRowDef="displayedColumns; sticky: true;"
              class="matHeaderRow">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="matHeaderRow"
              [ngClass]="{'responsive-mat-row' : screensize_ltmd, 'responsive-mat-row-small': screensize_ltsm}">
            </mat-row>
          </mat-table>
          <mat-paginator #formsPaginator="matPaginator" [pageSizeOptions]="[10, 25, 50]"
            showFirstLastButtons [pageSize]="5" aria-label="Select page">
          </mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </section>
</div>