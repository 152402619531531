import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'underscore';

@Component({
  selector: 'app-additional-data-viewer',
  templateUrl: './additional-data-viewer.component.html',
  styleUrls: ['./additional-data-viewer.component.scss']
})
export class AdditionalDataViewerComponent implements OnInit {
  expectedList: any;
  actualList: any;

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public userData: any) { }

  ngOnInit(): void {
    this.expectedList = this.userData.expected ? Object.entries(this.userData.expected): [];
    this.actualList = (this.userData.actual || this.userData.challenge) ? Object.entries(this.userData.actual ? this.userData.actual.challenge : this.userData.challenge) : [];
  }

  closeModal(): void{
    this.dialogRef.close();
  }

  calcValue(item: any, isExpected: boolean): string {
    let itemData = isExpected ? item.value[1].value1 : item.value[1];
    if(item.value[0]==='INVOICEAMOUNT') {
      let finalVal = '';
      if(itemData.includes(',')){
        const items = itemData.trim().split(',').map(elem => {return '$' + Number.parseFloat(elem).toFixed(Math.max(2, (elem.toString().split('.')[1] || []).length))});
        finalVal += items.join(', ');
      }
      else {
        finalVal += '$' + Number.parseFloat(itemData).toFixed(Math.max(2, (itemData.toString().split('.')[1] || []).length));
      }
      return finalVal;
    }

    else {
      return itemData.includes(',')?itemData.trim().split(',').join(', ') : itemData;
    }
  }
}