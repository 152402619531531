import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CsrDataService } from './csr/dataservice/csr-data.service';
import { LocationDataService } from './csr/dataservice/location.dataservice';

export const _CSR_MODULE_CONFIG_ = '_CSR_MODULE_CONFIG_';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule]
  , providers: [CsrDataService, LocationDataService]
})
export class CsrDataProxyModule {
  static forRoot(config: any): ModuleWithProviders<CsrDataProxyModule> {
    return {
      ngModule: CsrDataProxyModule,
      providers: [
        LocationDataService, CsrDataService,
        {
          provide: _CSR_MODULE_CONFIG_
          , useValue: config
        }
      ]
    };
  }
}