import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { CustomerserviceDataService } from '@londonhydro/customerservice-api';

@Component({
  selector: 'app-customer-registration-form',
  templateUrl: './customer-registration-form.component.html',
  styleUrls: ['./customer-registration-form.component.scss']
})
export class CustomerRegistrationFormComponent extends AbstractComponent implements OnInit, OnDestroy {

  customerSearched: any;
  customerRegForm: UntypedFormGroup = this.formBuilder.group({
    customerId: ['', [Validators.required, Validators.minLength(3),
    Validators.maxLength(20), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
  });
  portals: string[] = ['MyLondonHydro Portal', 'Property Management Portal', 'Builders Portal'];
  showContent: boolean;
  noCustomerMsg: boolean;
  selectedOption: string = 'MyLondonHydro Portal';
  isChildCustomer = false;
  masterCustomerId = '';
  newuser = null;
  unitAndTenantCnt: any;
  constructor(injector: Injector,route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder, private customerServiceDataService: CustomerserviceDataService) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  findCustomerbyId(): void {
    this.showContent = false;
    if (this.selectedOption === 'Property Management Portal') {

      this.customerServiceDataService.findCustomerUnitAndTenantCounts(this.customerRegForm.controls.customerId.value);
      this.customerServiceDataService.findCustomerUnit.subscribe(result => {
        this.unitAndTenantCnt = result;
        console.log('masterCustomerId:::::::', this.unitAndTenantCnt.masterCustomerId);
        if (this.unitAndTenantCnt.masterCustomerId !== 0 && this.unitAndTenantCnt.masterCustomerId !== this.newuser.customerId) {
          this.isChildCustomer = true;
          this.masterCustomerId = this.unitAndTenantCnt.masterCustomerId;
        }
        console.log('masterCustomerId:::1111::::', this.masterCustomerId, ' :::::isChildCustomer :::::: ', this.isChildCustomer);
      }, error => {
        console.log('fail', error);
        this.unitAndTenantCnt = {};
      });
    }
    this.customerServiceDataService.getCustomerSearch(this.customerRegForm.controls.customerId.value);
    this.customerServiceDataService.csrSearch.subscribe /* this.newuser.customerId */
      (response => {
        if (response !== null) {
          console.log(response)
          this.showContent = true;
          this.customerSearched = response;
        } else {
          this.noCustomerMsg = true;
          this.customerSearched = null;
        }
      }, error => {
        console.log(error);
      })
  }
}
