<div id="modal-content-wrapper">
<section id="modal-body">
		
<mat-card appearance="outlined" class="ux-card border border-light p-0">
			<mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title>Send Invitation Email</mat-card-title>
				<button (click)="closeModal()" class="btn btn-link " type="button" id="dismiss" aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>
	<mat-card-content  class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch" >
		<div fxLayoutAlign="start start">Email will be sent to the below mentioned email id.</div>
		<form fxLayout="column" fxLayoutGap="20px">
			<div fxLayout="column">
			
			<div fxflex="100" fxLayout="row" fxLayout_xs="column">
				<div class="align-form" [ngClass]="{'align-form-xs' : screensize_xs}" fxFlex="100"
				 fxLayout_xs="column" fxLayout="row" fxFlex_xs="100" fxLayoutGap="10px" fxLayoutGap_xs="5px">
					<mat-label fxFlex="30" fxFlex_xs="100"><h4>Email:</h4></mat-label>

					<mat-label fxFlex="70" fxFlex_xs="100"><h4 class="font-weight-bold">{{userData.emailAddress}}</h4></mat-label>
				</div>
				
			</div>
			
			</div>
		</form>
		    <mat-card-actions fxLayoutGap=" 20px" fxLayoutAlign="end end">
				<button class="btn btn-sm ux-icon-button" (click)="closeModal()">Cancel</button>
				<button class="btn btn-sm ux-icon-button mr-2" data-toggle="tooltip" title="Send Registration Email" (click)="sendRegistrationEmail()">Submit</button>
		</mat-card-actions>
		</mat-card-content>
	</mat-card>
	</section>
	</div>