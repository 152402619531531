<ng-container>
  <mat-card appearance="outlined" fxFlex="100" fxLayout="column" fxFlexAlign="space-between stretch" class="widget-card">
    <mat-card-header class="widget-card-header" [ngClass]="headerClass">
      @if (iconName) {
        <mat-icon mat-card-avatar [svgIcon]="iconName" class="mr-2">{{iconName}}</mat-icon>
      }
      <mat-card-title-group>
        <mat-card-title class="widget-title ml-0" [ngClass]="subTitle?'mt-2':'mt-3'"><span [innerHTML]="title"></span>
      </mat-card-title>
      @if (subTitle) {
        <mat-card-subtitle class="widget-sub-title">{{subTitle}}</mat-card-subtitle>
      }
    </mat-card-title-group>
    <div fxFlex></div>
    @if (enableToggleMode) {
      <button mat-icon-button (click)="toggleView()">
        <mat-icon>
        {{this.minimized?'fullscreen':'fullscreen_exit'}}</mat-icon>
      </button>
    }

  </mat-card-header>
  <mat-card-content fxFlex class="widget-card-body" [ngClass]="contentClass">
    <ng-content></ng-content>
  </mat-card-content>
  @if (actionRow || enableBackButton) {
    <mat-card-actions class="widget-card-footer">
      @if (enableBackButton) {
        <ng-container [ngTemplateOutlet]="back">
        </ng-container>
      }
      <ng-content select="[widgetAction]"></ng-content>
    </mat-card-actions>
  }
  @if (footer) {
    <mat-card-footer [ngClass]="footerClass">
      <span fxFlex="100">{{footer}}</span>
    </mat-card-footer>
  }
</mat-card>
</ng-container>
<ng-template #back>
  <button mat-raised-button uxlib-btn-back>
    <mat-icon>back</mat-icon>
    Back
  </button>
</ng-template>