<div id="modal-content-wrapper">
  <section id="modal-body">
    <mat-card appearance="outlined" class="ux-card border border-light p-0">
      <mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title>Service Request Details</mat-card-title>
        <button (click)="closeModal()" class="btn btn-link text-secondary" type="button" id="dismiss"
          aria-label="Close">
          <mat-icon class="material-symbols-outlined">close</mat-icon>
        </button>
      </mat-card-header>

      <mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-accordion class="border border-light p-0">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
              fxLayoutAlign="space-between center">
              <mat-panel-title class="mb-0 text-white font-weight-bold">Request Details</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
              @for (attribute of (formAttributeConfig | listFilter: 'group': 'request'); track attribute) {
                <div fxFlex="50" fxFlex_lt-sm="100" fxLayout="row" fxLayoutAlign="space-between center"
                  >
                  <div fxFlex="40" fxFlex_lt-sm="40" fxFlex_lt-md="25" class="p-2">{{attribute.label}}:
                  </div>
                  <div fxFlex="60" fxFlex_lt-sm="60" fxFlex_lt-md="75"
                    class="p-2 font-weight-bold text-break"
                    [ngClass]="(attribute.class || '') + ' ' + (attribute.dataClass? formDetails[attribute.dataValue] : '')">
                    @if (attribute.type === 'string' && !attribute.translate) {
                      <span
                      >{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
                    }
                    @if (attribute.type === 'string' && attribute.translate) {
                      <span
                        >{{formDetails.getDeepData(attribute.name) | labelTranslate}}
                      </span>
                    }
                    @if (attribute.type === 'formDisplayName' && !isFormOriginMyaccount) {
                      <span
                        >{{formDetails.getDeepData(attribute.name)}}
                      </span>
                    }
                    @if (attribute.type === 'formDisplayName' && isFormOriginMyaccount) {
                      <span
                        >
                        <span>{{attribute.exceptionalFormToTranslate === formDetails.getDeepData('form.name')?(formDetails.getDeepData('form.name') | labelTranslate) : formDetails.getDeepData(attribute.name)}}</span>
                      </span>
                    }
                    @if (attribute.type === 'number') {
                      <span
                      >{{formDetails.getDeepData(attribute.name) | number: '1.0-2'}}</span>
                    }
                    @if (attribute.type === 'date') {
                      <span
                      >{{formDetails.getDeepData(attribute.name) ? (formDetails.getDeepData(attribute.name) | date: 'short' : uiTimezone ) : (attribute.default || '-')}}</span>
                    }
                    @if (attribute.type === 'shortDate') {
                      <span
                      >{{formDetails.getDeepData(attribute.name) ? (formDetails.getDeepData(attribute.name) | date: 'shortDate' : uiTimezone ) : (attribute.default || '-')}}</span>
                    }
                    @if (attribute.type === 'hidden') {
                      <span
                      class="d-none">{{formDetails.getDeepData(attribute.name)}}</span>
                    }
                    @if (attribute.type === 'assignedUser') {
                      <span
                      >{{formDetails.getDeepData(attribute.name) && formDetails.getDeepData('assignedTo')?formDetails.getDeepData(attribute.name):(formDetails.getDeepData('assignedUser.userName') && formDetails.getDeepData('assignedTo')?formDetails.getDeepData('assignedUser.userName'):attribute.default)}}</span>
                    }
                  </div>
                </div>
              }
            </mat-card-content>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion class="border border-light p-0 mt-2">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
              fxLayoutAlign="space-between center">
              <mat-panel-title class="mb-0 text-white font-weight-bold">User/Customer Details</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
              @for (attribute of (formAttributeConfig | listFilter: 'group': 'form'); track attribute) {
                <div [ngClass]="(attribute.width==100)?'fxFlex_100':'fxFlex_50'" fxFlex_lt-sm="100" fxLayout="row"
                  fxLayoutAlign="space-between center"
                  [hidden]="!formDetails.getDeepData(attribute.name)">
                  <div [ngClass]="(attribute.width==100)?'fxFlex_15':'fxFlex_40'" fxFlex_lt-sm="40" fxFlex_lt-md="25"
                    class="p-2">{{attribute.label}}:
                  </div>
                  <div [ngClass]="(attribute.width==100)?'fxFlex_85':'fxFlex_60'" fxFlex_lt-sm="60" fxFlex_lt-md="75"
                    class="p-2 font-weight-bold text-break">
                    @if (attribute.type === 'string') {
                      <span
                      >{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
                    }
                    @if (attribute.type === 'number') {
                      <span
                      >{{masterForm[attribute.name] | number: '1.0-2'}}</span>
                    }
                    @if (attribute.type === 'date') {
                      <span
                      >{{masterForm[attribute.name] | date: 'short' : uiTimezone }}</span>
                    }
                    @if (attribute.type === 'shortDate') {
                      <span
                      >{{masterForm[attribute.name] | date: 'shortDate' : uiTimezone }}</span>
                    }
                    @if (attribute.type === 'hidden') {
                      <span
                      class="d-none">{{masterForm[attribute.name]}}</span>
                    }
                  </div>
                </div>
              }
            </mat-card-content>
          </mat-expansion-panel>
        </mat-accordion>

        @if (isAdditionalSectionRequired()) {
          <mat-accordion class="border border-light p-0 mt-2">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
                fxLayoutAlign="space-between center">
                <mat-panel-title class="mb-0 text-white font-weight-bold">Additional Details
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-card-content fxLayout="row wrap" class="mb-0" fxLayoutAlign="space-between center">
                @for (attribute of (formAttributeConfig | listFilter: 'group': 'additional'); track attribute) {
                  <div fxLayout="row"
                    [ngClass]="(formDetails.getDeepData(attribute.name) && attribute.type != 'bankingInfo')?'fxFlex_50':'fxFlex_90'" [hidden]="!formDetails.getDeepData(attribute.name)" fxFlex_xs="100">
                    @if (formDetails.getDeepData(attribute.name) ) {
                      <app-additional-details [formDetails]="formDetails" [attribute]="attribute" fxLayout="row" fxFlex="100"
                      fxLayoutAlign="space-between center"></app-additional-details>
                    }
                  </div>
                }
              </mat-card-content>
            </mat-expansion-panel>
          </mat-accordion>
        }

        @if (isPaymentTermsSectionRequired()) {
          <mat-accordion class="border border-light p-0 mt-2">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
                fxLayoutAlign="space-between center">
                <mat-panel-title class="mb-0 text-white font-weight-bold">Payment Terms
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-card-content fxLayout="row wrap" class="mb-0" fxLayoutAlign="space-between center">
                @for (attribute of (formAttributeConfig | listFilter: 'group': 'paymentTerms'); track attribute) {
                  <div fxLayout="row" [class]=" attribute.class "
                    [ngClass]="(attribute.type === 'array')?'fxFlex_100':'fxFlex_50'" [hidden]="!formDetails.getDeepData(attribute.name)" fxFlex_xs="100">
                    <div fxLayout="row" fxFlex="100"
                      fxLayoutAlign="space-between center">
                      @if (attribute.type !== 'array') {
                        <div fxFlex="35" fxFlex_lt-sm="40" fxFlex_lt-md="25" class="p-2">
                        {{attribute.label}}:</div>
                      }
                      @if (attribute.type !== 'array') {
                        <div fxFlex="65"  fxFlex_lt-sm="60" fxFlex_lt-md="75"
                          class="p-2 font-weight-bold text-break"
                          [ngClass]="(attribute.class || '') + ' ' + (attribute.dataClass? formDetails[attribute.dataValue] : '')">
                          @if (attribute.type === 'string' && !attribute.translate) {
                            <span>{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
                          }
                          @if (attribute.type === 'currency') {
                            <span>{{formDetails.getDeepData(attribute.name)  | currency:'USD':'symbol'}}</span>
                          }
                        </div>
                      }
                      @if (attribute.type === 'array') {
                        <div fxFlex="100"
                          class="text-break p-2"
                          [ngClass]="(attribute.class || '') + ' ' + (attribute.dataClass? formDetails[attribute.dataValue] : '')">
                          @if (attribute.type === 'array') {
                            <div fxFlex="100"  fxLayout="column" fxLayoutGap="10px">
                              @for (item of formDetails['paymentInstallments']; track item; let i = $index) {
                                <div fxFlex="100" fxLayout="row" fxLayout_xs="column" [class]="i===formDetails['paymentInstallments'].length-1  && formDetails.getDeepData('totalAmount') ?' '+'border-btm'+' ':''">
                                  @for (subAttribute of attribute.subAttributeListConfig; track subAttribute) {
                                    <span fxFlex="33" fxFlex_xs="100">
                                      <span fxFlex="60" fxFlex_xs="100" class="font-weight-light">{{subAttribute.label}} &nbsp;{{'#'+(i+1)}}:&nbsp;</span>
                                      <span fxFlex="40" fxFlex_xs="100" class="font-weight-bold">
                                        @if (subAttribute.type==='currency') {
                                          <span>{{item[subAttribute.name]  | currency:'USD':'symbol' || '-'}}</span>
                                        }
                                        @if (subAttribute.type==='string') {
                                          <span>{{item[subAttribute.name] || '-'}}</span>
                                        }
                                        @if (subAttribute.type==='date') {
                                          <span>{{item[subAttribute.name] | date: 'short' : uiTimezone || '-'}}</span>
                                        }
                                        @if (subAttribute.type==='shortDate') {
                                          <span>{{item[subAttribute.name] | date: 'shortDate' : uiTimezone || '-'}}</span>
                                        }
                                      </span>
                                    </span>
                                  }
                                </div>
                              }
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </div>
                }
              </mat-card-content>
            </mat-expansion-panel>
          </mat-accordion>
        }

        <!-- <mat-accordion class="border border-light p-0 mt-2">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
            fxLayoutAlign="space-between center">
            <mat-panel-title class="mb-0 text-white font-weight-bold">User/Customer Details</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
            <div [fxFlex]="attribute.width?attribute.width:50" [fxFlex_lt-sm]="100" fxLayout="row"
              fxLayoutAlign="space-between center"
              *ngFor="let attribute of (formAttributeConfig | listFilter: 'group': 'form')"
              [fxHide]="!formDetails.getDeepData(attribute.name)">
              <div [fxFlex]="attribute.width==100?15:40" [fxFlex_lt-sm]="40" [fxFlex_lt-md]="25"
                class="p-2">{{attribute.label}}:
              </div>
              <div [fxFlex]="attribute.width==100?85:60" [fxFlex_lt-sm]="60" [fxFlex_lt-md]="75"
                class="p-2 font-weight-bold text-break">
                <span
                *ngIf="attribute.type === 'string'">{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
                <span
                *ngIf="attribute.type === 'number'">{{masterForm[attribute.name] | number: '1.0-2'}}</span>
                <span
                *ngIf="attribute.type === 'date'">{{masterForm[attribute.name] | date: 'shortDate': uiTimezone}}</span>
                <span *ngIf="attribute.type === 'hidden'"
                class="d-none">{{masterForm[attribute.name]}}</span>

              </div>

            </div>
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion> -->

      <mat-accordion class="border border-light p-0 mt-2">
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
            fxLayoutAlign="space-between center">
            <mat-panel-title class="mb-0 text-white font-weight-bold">Uploaded Files</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content class="mb-0">
            @if (fileCategories && fileCategories.length > 0) {
              <div
                fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px" class="p-4">
                @for (category of fileCategories; track category) {
                  <div>
                    <app-file-upload fxFlex="100" [uploadConfig]="fileViewerConfig[category]"
                      [mode]="viewMode" [documentRef]="fileListWithCategory[category]">
                    </app-file-upload>
                  </div>
                }
              </div>
            } @else {
              <div fxLayout="column" fxLayoutAlign="center start" class="p-4">
                <p class="font-weight-bold text-secondary">There are no files uploaded for the
                selected form.</p>
              </div>
            }
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion class="border border-light p-0 mt-2">
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
            fxLayoutAlign="space-between center">
            <mat-panel-title class="mb-0 text-white font-weight-bold">Review Details</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content fxLayout="row" fxLayoutAlign="space-between center" class="mb-0">
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
              <div fxHide_lt-sm fxShow fxFlex="100" fxLayout="row"
                fxLayoutAlign="space-between center" class="border-bottom border-secondary">
                <div fxFlex="40" fxFlex_lt-md="30" class="p-2 font-weight-bold">
                  <span class="mr-2">#</span><span>Review/Action</span>
                </div>
                <div fxFlex="25" fxFlex_lt-md="40" class="p-2 font-weight-bold">
                  <span>By</span>
                </div>
                <div fxFlex="25" fxFlex_lt-md="30" class="p-2 font-weight-bold">
                  <span>On</span>
                </div>
              </div>
              @if (auditReviews && auditReviews.length > 0) {
                <div fxFlex="100" fxLayout="row wrap"
                  >
                  @for (review of auditReviews; track review; let i = $index) {
                    <div fxFlex="100" fxLayout="row" fxLayout_lt-sm="column"
                      fxLayoutAlign="space-between center"
                      [ngStyle]="{'border-bottom' : screensize_ltsm ? 'double' : null}">
                      <div fxFlex="40" fxFlex_lt-sm="100" fxFlex_lt-md="30" class="p-2"
                        [ngClass]="{'mr-auto' : screensize_ltsm}">
                        <span class="mr-2">
                          <span class="font-weight-bold" fxShow_lt-sm fxHide><span
                          class="mr-2">#</span>
                          <span>Review/Action-</span></span>{{(i+1)}}.</span><span>{{review.comment}}.</span>
                          @if (review.systemMessage && review.systemMessage.code) {
                            <span> {{review.systemMessage.code | labelTranslate}}</span>
                          }
                        </div>
                        <div fxFlex="25" fxFlex_lt-sm="100" fxFlex_lt-md="40" class="p-2"
                          [ngClass]="{'mr-auto' : screensize_ltsm}">
                          <span fxShow_lt-sm fxHide class="font-weight-bold"
                          [ngStyle]="{'white-space' : screensize_ltsm ? 'pre' : null}">By: </span>
                          <span>{{review.commentedBy}}</span>
                        </div>
                        <div fxFlex="25" fxFlex_lt-sm="100" fxFlex_lt-md="30" class="p-2"
                          [ngClass]="{'mr-auto' : screensize_ltsm}">
                          <span fxShow_lt-sm fxHide class="font-weight-bold ">On:
                          </span>
                          <span>{{review.createdDate | date: 'short': uiTimezone}}</span>
                        </div>
                      </div>
                    }
                  </div>
                } @else {
                  <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                    <p class="font-weight-bold text-secondary">There are no reviews for this service
                    request.</p>
                  </div>
                }
                <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                  <div fxFlex="50" class="p-2">
                    @if (!reviewMode) {
                      <button (click)="toggleReviewMode()" data-toggle="tooltip" title="Add Review"
                        type="button" class="btn btn-sm ux-icon-button">
                        <mat-icon data-placement="bottom">post_add</mat-icon>
                      </button>
                    }
                    @if (reviewMode) {
                      <mat-form-field class="review-form-field" appearance="fill">
                        <input matInput type="text" [(ngModel)]="inpReviewComment">
                      </mat-form-field>
                    }
                  </div>
                  <div fxFlex="50" class="p-2" fxLayoutAlign="start center">
                    @if (reviewMode) {
                      <button (click)="saveReviewComment()" type="button"
                        class="btn btn-sm ux-icon-button" data-toggle="tooltip"
                      title="Save Review Comment">Save</button>
                    }
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion class="border border-light p-0 mt-2">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
              fxLayoutAlign="space-between center">
              <mat-panel-title class="mb-0 text-white font-weight-bold">Generated Alerts</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content fxLayout="row" fxLayoutAlign="space-between center" class="mb-0">
              <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex="100" fxHide_lt-sm fxShow fxLayout="row"
                  fxLayoutAlign="space-between center" class="border-bottom border-secondary">
                  <div fxFlex="10" fxFlex_lt-md="30" class="p-2 font-weight-bold">
                    <span>#</span>
                  </div>
                  <div fxFlex="65" fxFlex_lt-md="40" class="p-2 font-weight-bold">
                    <span>Message/Alert</span>
                  </div>
                  <div fxFlex="25" fxFlex_lt-md="30" class="p-2 font-weight-bold">
                    <span>Generated On</span>
                  </div>
                </div>
                @if (requestAlerts && requestAlerts.length > 0) {
                  <div fxFlex="100" fxLayout="row wrap"
                    >
                    @for (alert of requestAlerts; track alert; let i = $index) {
                      <div fxFlex="100" fxLayout="row" fxLayout_lt-sm="column"
                      [ngStyle]="{'border-bottom' : screensize_ltsm ? 'double' : null}" fxLayoutAlign="space-between center"
                        >
                        <div fxFlex="10" fxFlex_lt-sm="100" fxFlex_lt-md="30" class="p-2"
                          [ngClass]="{'mr-auto' : screensize_ltsm}">
                          <span fxShow_lt-sm fxHide class="font-weight-bold "># </span>
                          <span>{{alert.serialNumber}}</span>
                        </div>
                        <div fxFlex="65" fxFlex_lt-sm="100" fxFlex_lt-md="40" class="p-2" fxLayout="row"
                          [ngClass]="{'mr-auto' : screensize_ltsm}">
                          <span fxShow_lt-sm fxHide
                          class="font-weight-bold ">Message/Alert</span>
                          <div [class]="'bg-severity-' + alert.severity" class="mr-1">
                            @if (alert.severity != 'L') {
                              <svg class="bi flex-shrink-0 me-2" width="20" height="20"
                                >
                              <use xlink:href="#exclamation-triangle-fill" /></svg>
                            }
                            @if (alert.severity === 'L') {
                              <svg class="bi flex-shrink-0 me-2" width="20" height="20"
                                >
                              <use xlink:href="#info-fill" /></svg>
                            }
                          </div>
                          <span>{{alert.message}}</span>
                        </div>
                        <div fxFlex="25" fxFlex_lt-sm="100" fxFlex_lt-md="30" class="p-2"
                          [ngClass]="{'mr-auto' : screensize_ltsm}">
                          <span fxShow_lt-sm fxHide class="font-weight-bold ">Generated
                          On</span>
                          <span>{{alert.createdDate | date: 'short': uiTimezone}}</span>
                        </div>
                      </div>
                    }
                  </div>
                } @else {
                  <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                    <p class="font-weight-bold text-secondary">There are no alerts generated for the
                    request.</p>
                  </div>
                }
              </div>
            </mat-card-content>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-card-actions fxLayout="row" fxLayout_lt-sm="column" fxLayoutAlign="end center"
          fxLayoutGap_lt-sm="5px" fxLayoutGap="15px" class="bg-light px-2 fixed-boattom sticky-footer">
          @if (!assignedToMe && !formDetails.isCompleted && !this.superAccountUser) {
            <button type="button" class="btn btn-sm ux-icon-button" (click)="assignToMe()"
            data-toggle="tooltip" title="Assign to me">Assign to me</button>
          }
          @if (!assignedToMe && !formDetails.isCompleted && this.superAccountUser) {
            <button type="button" class="btn btn-sm ux-icon-button" (click)="assignToUserViewer()"
              data-toggle="tooltip"
            title="Assign">Assign</button>
          }
          @if (assignedToMe && !formDetails.isCompleted) {
            <button type="button" class="btn btn-sm ux-icon-button" (click)="unassign()"
              data-toggle="tooltip"
            title="Unassign">Unassign</button>
          }
          @if (formDetails['requestOrigin']!='myaccount') {
            <button type="button" class="btn btn-sm ux-icon-button" (click)="navigateToFormView()"
            data-toggle="tooltip" title="View Form">View Form</button>
          }
          @if (assignedToMe && formDetails.isInException) {
            <button type="button" class="btn btn-sm ux-icon-button" (click)="updateWorkflow('QUEUED')"
              data-toggle="tooltip"
            title="Move to Queue">Move to Queue</button>
          }
          @if (assignedToMe && skipBtnEnabled) {
            <button type="button" class="btn btn-sm ux-icon-button" (click)="skipWorkflow()"
              data-toggle="tooltip"
            title="Skip">{{'btn.skip.' + formDetails.nextWorkflowApplicable() | labelTranslate}}</button>
          }
          @if (assignedToMe && formDetails.nextWorkflowApplicable() == 'CIS') {
            <button type="button" class="btn btn-sm ux-icon-button" (click)="confirmWorkflowUpdate('CIS')"
              data-toggle="tooltip"
            title="Submit to North Star">{{'btn.submitToCIS' | labelTranslate}}</button>
          }
          @if (assignedToMe && formDetails.nextWorkflowApplicable() == 'BILLING') {
            <button type="button" class="btn btn-sm ux-icon-button" (click)="confirmWorkflowUpdate('BILLING')"
              data-toggle="tooltip"
            title="Submit to Billing">{{'btn.submitToBilling' | labelTranslate}}</button>
          }
          @if (assignedToMe && formDetails.nextWorkflowApplicable() == 'BLANKET') {
            <button type="button" class="btn btn-sm ux-icon-button" (click)="updateWorkflow('BLANKET')"
              data-toggle="tooltip"
            title="Blanket">{{'btn.blanketUpdate' | labelTranslate}}</button>
          }
          @if (assignedToMe && formDetails.nextWorkflowApplicable() == 'COMPLETED') {
            <button type="button" class="btn btn-sm ux-icon-button" (click)="confirmWorkflowUpdate('COMPLETED')"
            data-toggle="tooltip" title="Mark as Completed">Mark as Completed</button>
          }
          @if (assignedToMe && !formDetails.isCancelled && !formDetails.isCompleted) {
            <button type="button" class="btn btn-sm ux-icon-button" (click)="updateWorkflow('CANCELLED')"
            data-toggle="tooltip" title="Cancel Request">Cancel Request</button>
          }
          @if (assignedToMe && !formDetails.isInException && !formDetails.isCompleted) {
            <button type="button" class="btn btn-sm ux-icon-button"
              (click)="updateWorkflowWithRemarks('EXCEPTION')"
            data-toggle="tooltip" title="Raise Exception">Raise Exception</button>
          }

        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </section>
</div>