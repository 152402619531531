<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="menu-bar" fxShow fxHide_lt-sm>
  <div fxLayout="row" fxLayoutAlign="start center">
    @for (menu of topMenuItems; track menu; let i = $index) {
      <div fxLayout="column" fxLayoutAlign="center center"
        [class]="selectedMenu.name === menu.name ? 'active':''" >
        <div class="menu-tab " [class]="selectedMenu.name === menu.name ? 'active':''">
          <button fxFlex="100" class="btn btn-link menu-item" (click)="selectMenuItem(menu)"
            (mouseenter)="showMenuItem(menu)" [id]="'topmenu_' + menu.id"
            [class]="(menu.styleClass || '') + (selectedMenu.name === menu.name ? 'active':'')">
            {{menu.label}}
          </button>
        </div>
        @if (showSubMenuBar) {
          <div class="menu-expand-icon-div">
            @if (menu.name===subMenuActive?.name || menu.id===subMenuActive?.parentMenuId) {
              <mat-icon class="menu-expand-icon">arrow_drop_up
              </mat-icon>
            }
          </div>
        }
      </div>
    }
  </div>
	<div  class="login-name" [class]="selectedMenu?.id === 21 || selectedMenu?.id ===22 || 
	selectedMenu?.id ===23 || selectedMenu?.id === 24 || selectedMenu?.id ===31 || selectedMenu?.id ===32 ? 'd-block':'d-none'">
    You are signed in as {{loggedInUserName}}
  </div>
</mat-toolbar>

@if (showSubMenuBar) {
  <div (clickOutside)="toggleSubMenuBar($event)" class="sub-menu" fxShow fxHide_lt-sm>
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="menu-bar sub-menu-bar" #subMenu>
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" class="sub-menu-toolbar">
        @for (subMenu of subMenuItems; track subMenu; let i = $index) {
          <div class="menu-tab sub-menu-tab" fxLayout="column"
            [class]="selectedMenu.name === subMenu.name ? 'active':''" fxFlex="50" >
            <button class="btn btn-link menu-item sub-menu-item" (click)="selectMenuItem(subMenu)"
              [class]="selectedMenu.name === subMenu.name ? 'active':''">
              {{subMenu.label}}
            </button>
          </div>
        }
      </div>
    </mat-toolbar>
  </div>
}


<!-- sidenav for responsive-->
@if (topMenuItems) {
  <div fxHide fxShow_lt-sm>
    <mat-toolbar class="responsive-toolbar-sm">
      <button type="button" mat-button (click)="drawer.toggle()" class="ml-auto">
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar>
    <mat-drawer-container class="top-menu-container" autosize >
      <mat-drawer #drawer class="top-menu-sidenav" mode="over" [opened]="false">
        <div fxLayout="row"  >
          <div fxLayout="column" class="ml-auto">
            <h3>Menu</h3>
          </div>
          <div fxLayout="column" class="ml-auto">
            <button (click)="drawer.toggle()"  class="close" type="button" id="dismiss" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="mt-3" fxLayout="row">
          <div fxLayout="column" ><mat-icon>person</mat-icon></div>
          <div fxLayout="column" class="login-name pt-1 pr-2" >
            {{loggedInUserName}}
          </div>
        </div>
        <div class="mt-5">
          @for (menu of topMenuItems; track menu; let i = $index) {
            <div fxLayout="column"
              [class]="selectedMenu.name === menu.name ? 'active':''">
		<div class="menu-tab" [class]="selectedMenu.name === menu.name
			||selectedMenu?.parentMenuId===menu.id || responsiveSelectedMenu?.name === menu.name 
			|| responsiveSelectedMenu?.parentMenuId===menu.id? 'active':'' +' '+'mt-3' ">
                <button fxFlex="100" class="btn btn-link menu-item"  fxLayoutAlign="start left" (click)="selectMenuItem(menu)"
                  (mouseenter)="showMenuItem(menu)" [id]="'topmenu_' + menu.id"
                  [class]="(menu.styleClass || '') + (selectedMenu.name === menu.name ? 'active':'')">
                  {{menu.label}}
                </button>
                <mat-icon class="arrow-style">
                  {{showSubMenuBar && menu.hasSubMenu && (responsiveSelectedMenu?.id===menu.id ||
                  responsiveSelectedMenu?.parentMenuId===menu.id)?'arrow_drop_down':
                  (menu.hasSubMenu?'arrow_right':'')}}
                </mat-icon>
              </div>
              @if (showSubMenuBar && menu.hasSubMenu && (responsiveSelectedMenu?.id===menu.id ||
                responsiveSelectedMenu?.parentMenuId===menu.id)) {
                <div (clickOutside)="toggleSubMenuBar($event)" fxLayout="column" fxLayoutAlign="center center"
                  fxLayoutAlign_xs="start start">
                  <div #subMenu>
                    @for (subMenu of menu.children; track subMenu; let i = $index) {
                      <div  fxLayout="row"
                        [class]="selectedMenu.name === subMenu.name ? 'active':'' " class="menu-tab ">
                        <button  (click)="selectMenuItem(subMenu)" class="btn btn-link menu-item sub-menu-item"
                          [class]="selectedMenu.name === subMenu.name ? 'active':''">
                          {{subMenu.label}}
                        </button>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </mat-drawer>
    </mat-drawer-container>
  </div>
}