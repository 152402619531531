

<mat-card appearance="outlined" class="ux-card border border-light p-0" fxFlex="100">

  <mat-card-content class="content-body" fxLayout="row wrap" fxLayoutAlign="space-between stretch" fxLayoutGap="10" fxFlex="100">

    <mat-accordion class="border border-light p-0 mt-2" fxFlex="49" fxFlex_lt-sm="100" >
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
          fxLayoutAlign="space-between stretch">
          <mat-panel-title class="mb-0 text-white font-weight-bold">Old Values
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between stretch" class="mb-0">
          @if (oldValuesList) {
            <div fxFlex="100" fxLayout="row wrap" fxLayout_xs="column"
              fxLayoutAlign="space-between center" fxLayoutAlign_xs="start">
              @for (item of oldValuesList | keyvalue; track item) {
                <div fxFlex="100" fxFlex_xs="100"  class="pt-2 pb-2 text-break">
                  <mat-label fxFlex="45" fxFlex_xs="100"> <span>{{item.value[0] | labelTranslate | replaceCharacter:'_':' ' | titlecase}}: </span></mat-label>
                  @if (item.value[1]) {
                    <mat-label fxFlex="55" fxFlex_xs="100" class="font-weight-bold"
                      [class]="(item.value[1].includes(',')?' text-break ':'')">
                      {{ item.value[0] === 'phone_number' ? (item.value[1] ? formatPhnNumber(item.value[1]) : '-') : (item.value[1] | labelTranslate)}}
                    </mat-label>
                  }
                  @else {
                    <mat-label fxFlex="55" fxFlex_xs="100" class="font-weight-bold">-</mat-label>
                  }
                </div>
              }
            </div>
          }
        </mat-card-content>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="border border-light p-0 mt-2" fxFlex="49" fxFlex_lt-sm="100" >
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
          fxLayoutAlign="space-between stretch">
          <mat-panel-title class="mb-0 text-white font-weight-bold">New Values
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between stretch" class="mb-0">
          @if (newValuesList) {
            <div fxFlex="100" fxLayout="row wrap" fxLayout_xs="column" fxLayoutAlign_xs="start"
              fxLayoutAlign="space-between center">
              @for (item of newValuesList | keyvalue; track item) {
                <div fxFlex_xs="100" fxFlex="100" class="pt-2 pb-2 text-break">
                  <mat-label fxFlex="45" fxFlex_xs="100" fxLayoutAlign="start baseline"> <span>{{item.value[0] | labelTranslate | replaceCharacter:'_':' ' | titlecase}}: </span></mat-label>
                  @if (item.value[1]) {
                    <mat-label fxFlex="55" fxFlex_xs="100" class="font-weight-bold">
                      {{ item.value[0] === 'phone_number' ? (item.value[1] ? formatPhnNumber(item.value[1]) : '-') : (item.value[1] | labelTranslate)}}
                    </mat-label>
                  }
                  @else {
                    <mat-label fxFlex="55" fxFlex_xs="100" class="font-weight-bold">-</mat-label>
                  }
                </div>
              }
            </div>
          }
        </mat-card-content>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>