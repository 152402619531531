import { Component, OnInit, OnDestroy, Inject, Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IamUserTxnService } from '@londonhydro/iam-api';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-close-online-account-form',
  templateUrl: './close-online-account-form.component.html',
  styleUrls: ['./close-online-account-form.component.scss']
})
export class CloseOnlineAccountFormComponent extends AbstractComponent implements OnInit, OnDestroy {
  submitted = false;
  apiCallPending = false;
  appName: string;
  isShellAccount: boolean;
  closeOnlineAccountForm: UntypedFormGroup = this.formBuilder.group({
    reason: ['', [Validators.required, Validators.maxLength(250), this.noWhitespaceValidator]],
    check: [false, [Validators.required]]
  });

  constructor(injector: Injector, route: ActivatedRoute, private formBuilder: UntypedFormBuilder,
              private iamUserTxnService: IamUserTxnService, private toastr: ToastrService,
              public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public userData: any) {
    super(injector);
   }

  ngOnInit(): void {
    this.appName = environment.appName;
    super.ngOnInit();
    this.isShellAccount = (typeof this.userData.customerId === 'undefined' || this.userData.customerId === null) ? true : false;
  }

  noWhitespaceValidator(control: UntypedFormControl): any {
    return (control.value || '').trim().length? null : { 'whitespace': true };       
  }

  closeOnlineAccount(): void {
    this.submitted = true;
    this.apiCallPending = true;
    const params = JSON.parse(JSON.stringify({
        remarks: this.closeOnlineAccountForm.controls.reason.value}));


    this.iamUserTxnService.closeOnlineAccountFromIAM(this.userData.id, params).subscribe(
      (      closeAcc: any) => {
        console.log('Close Online Account Successfull::::', closeAcc);
        this.toastr.success(this.isShellAccount ? 'Shell Account Deleted.' : 'Online Account Closed.', 'Success');
        this.closeModal('success');
        this.apiCallPending = false;
      },
      (      error: any) => {
        console.log('error closing online account::::', error);
        this.toastr.error(this.isShellAccount ? 'Unable to delete shell account.' : 'Unable to close online account.', 'Error');
        this.closeModal('error');
        this.apiCallPending = false;
      });

  }

  closeModal(str?: string): void {
    this.dialogRef.close(str);
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
