import { HttpParams } from '@angular/common/http';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { IamUserTxnService } from '@londonhydro/iam-api';

@Component({
  selector: 'app-edit-profile-form',
  templateUrl: './edit-profile-form.component.html',
  styleUrls: ['./edit-profile-form.component.scss']
})
export class EditProfileFormComponent extends AbstractComponent implements OnInit, OnDestroy {

  firstName: any;
  lastName: any;
  phoneNumber: any;
  id: any;
  profile: any;
  customerID: string;
  username: any;
  updateForm: UntypedFormGroup = this.formBuilder.group({
    firstName: ['', [Validators.required, Validators.maxLength(50), RxwebValidators.alphaNumeric()]],
    lastName: ['', [Validators.required, Validators.maxLength(50), RxwebValidators.alphaNumeric()]],
    phoneNumber: ['', [Validators.required, RxwebValidators.maxLength({ value: 10 }),
    RxwebValidators.digit()]],
  });
  submitted = false;

  constructor(injector: Injector, route: ActivatedRoute, private toastr: ToastrService,
     public formBuilder: UntypedFormBuilder, private iamUserTxnService: IamUserTxnService,
    router: Router) {
    super(injector);
  }

  userSessionLoaded(): void {
    if (this.user) {
      this.id = this.user.id;
      this.updateForm.controls.firstName.setValue(this.user.firstName);
      this.updateForm.controls.lastName.setValue(this.user.lastName);
      this.username = this.user.username;
    }
  }

  ngOnInit(): void {

  }
  updateMyProfile(): void {
    this.submitted = true;
    const params = new HttpParams()
      .set('id', this.id)
      .set('firstName', this.updateForm.controls.firstName.value)
      .set('lastName', this.updateForm.controls.lastName.value)
      .set('phoneNumber', this.updateForm.controls.phoneNumber.value);
    const paramsObject = params.keys().reduce((object, key) => {
      object[key] = params.get(key);
      return object;
    }, {});
    this.iamUserTxnService.updateMyProfile(this.id, paramsObject).subscribe(
      res => {
        console.log('Response', res);
        this.toastr.success('Your Request has been successfully saved.!!', 'success', {
          timeOut: 10000,
        });
      },
      error => {
        console.error('name change error::::', error);
        this.toastr.error('Your Name Change Error Occurs.!!', 'error', {
          timeOut: 10000,
        });
      });
  }

  closeMe(): void {
    this.router.navigate(['/csrapp/my-profile']);
  }

  ngOnDestroy(): void {
  }
}



