import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorLabelPipe } from '../../framework/shared/service/error-label.pipe';
import { IamUserTxnService } from '@londonhydro/iam-api';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-resend-invitation',
  templateUrl: './resend-invitation.component.html',
  styleUrls: ['./resend-invitation.component.scss']
})
export class ResendInvitationComponent extends AbstractComponent implements OnInit, OnDestroy {

  pageLoader: boolean;
  userData: any;
  breakPoint_observer_subscription_xs: Subscription;
  screensize_xs: boolean;

  constructor(injector: Injector, public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public modalData: any,
    private toastr: ToastrService, 
    private translatePipe: ErrorLabelPipe, private iamUserTxnService: IamUserTxnService, private breakPointObserver: BreakpointObserver) {
    super(injector);
    this.userData = modalData;
    console.log('this.userData:::', this.userData);
  }

  ngOnInit(): void {
    this.breakPoint_observer_subscription_xs = this.breakPointObserver.observe([
      Breakpoints.XSmall]
    ).subscribe(result => {
      if (result && result.matches) {
        this.screensize_xs = true;
      } else {
        this.screensize_xs = false;
      }
    });
  }

  sendRegistrationEmail(): void {
    this.pageLoader = true;

    this.iamUserTxnService.sendInvitationEmail(this.loginUser.id, this.userData).pipe(
      catchError(error => {
        this.closeModal('error');
        this.pageLoader = false;
        this.toastr.error('Unable to send the invitation email' + (error.statusText ? '. ' + this.translatePipe.transform(error.statusText) : ''), 'Error', {
          timeOut: 10000,
        });

        return of(null);
      })).subscribe(response => {
        console.log('response', response);
        if (response) {
          this.toastr.success('You have successfully sent the invitation email ', 'Success', {
            timeOut: 10000,
          });
          this.pageLoader = false;
          this.closeModal('success');

        }
      }
      );

  }
  closeModal(str?: string): void {
    this.dialogRef.close(str);
  }

  ngOnDestroy(): void {
    if (this.breakPoint_observer_subscription_xs) {
      this.breakPoint_observer_subscription_xs.unsubscribe();
    }
  }
}
